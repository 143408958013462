import { TextField,Autocomplete } from '@mui/material';
import React, {useState, useEffect,useRef} from 'react'
import axiosInstance from '../../utils/api'
import { errorToast, successToast } from '../../utils/toast'
import {BottomScrollListener} from 'react-bottom-scroll-listener';
import $ from 'jquery';

const MINUTE_MS = 10000;
const Showcase = () => {
    const [loading, setLoading] = useState(false)
    const [timelineList, setTimelineList] = useState([])
    const [treeData, setTreeData] = useState({
        serial:"",
        next_tree: "",
        previous_tree: ""
    })
    const [treeList, setTreeList] = useState([]) 
    const [serialSelect, setSerialSelect] = useState({
        serial: ""
    })
    
    const [scrollSpeed, setScrollSpeed] = useState(10)
    const [duration, setDuration] = useState(3)
    let nextTree = null
    let treeList2 = []
    const [intervalId, setIntervalId] = useState(null)
    const [pauseStatus, setPauseStatus] = useState(true)
    const [playStatus, setPlayStatus] = useState(false)
    const [playDisabled, setPlayDisabled] = useState(false)

    function getData(treeid) {
        clearAllTimeout()
        // initial setSpeed
        // save value to storage bcs Event.register does not read updated state.
        if (localStorage.getItem('speed') == 0 || localStorage.getItem('speed') == null) {
            localStorage.setItem('speed', 10)
        }

        // window.scrollTo(0, 0) // this is the motherfcking issue
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/trees/timeline/growth/${treeid}/`).then(res => {
            setLoading(false)
            setTimelineList(res.data.growth)
            setTreeData({
                breed: res.data.breed,
                farm: res.data.farm,
                ref: res.data.ref,
                serial: res.data.serial,
                next_tree: res.data.next_tree,
                previous_tree: res.data.previous_tree,
            })
            nextTree = res.data.next_tree
            setSerialSelect(res.data)

            if (window.innerHeight < document.body.offsetHeight) {
                scrollDown()
            } else if (window.innerHeight == document.body.offsetHeight) {
                setTimeout(()=>{
                    getData(res.data.next_tree)
                }, 3000)
            }
            // if ( window.innerHeight >= document.body.offsetHeight) {
            //     getData(res.data.next_tree)
            // } else {
            //     scrollDown()
            // }
        }).catch(err => {
            setLoading(false)
            console.log(234, err)
            errorToast("Server Error")
        })
    }
    // useEffect(() => {
    //     if (treeList.length > 0) {
    //         let treeSelected = treeList.filter(tree => {
    //             return tree.treeid == nextTree
    //         })
    //         console.log(treeSelected[0])
    //         // setSerialSelect(treeSelected[0])
    //     }
    // }, [treeList])
    


    useEffect(() => {
        try {
            // Hide menu bar upon load if authenticated
            document.getElementById("menu-bar").click()
        } catch {
            // else dont havemenubar, so just widen
            document.getElementsByClassName("content-wrapper")[0].style['margin-left']=0
        }
        localStorage.removeItem("speed")

        loadTree()

        return () => {
            clearTimeout(intervalId)
        }
    }, [])

    
    function loadTree() {
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/trees`).then(res =>{
            setLoading(false)
            let trees_temp = res.data.filter( (t) => {
                if (t.serial) {
                    return t
                }
            })
            setTreeList(trees_temp)
            setSerialSelect(res.data[0])
            getData(res.data[0].treeid)

            treeList2 = res.data
        }).catch(err => {
            setLoading(false)
            console.log(err)
        })
    }

    function handlePrevious() {
        getData(treeData.previous_tree)
    }

    function handleNext() {
        getData(treeData.next_tree)
    }
    
    
    function scrollDown() {
        setPauseStatus(true)
        setPlayStatus(false)
        console.log("SCROLL SPEED " + localStorage.getItem('speed') )
        setTimeout(() => {
            $('body,html').animate({
                scrollTop: $(document).height() - $(window).height()
            }, {
                duration: convertToMiliSecond(scrollSpeed),
                easing: "linear",
                
            });
        }, 2000);
    }

    function handlePause(e) {
        // Stop scroll
        setPauseStatus(false)
        setPlayStatus(true)
        setLoading(false)
        clearAllTimeout()

        $("html, body").stop().unbind('scroll mousedown DOMMouseScroll mousewheel keyup'); // This identifies the scroll as a user action, stops the animation, then unbinds the event straight after (optional)
        $("html, body").stop().unbind('scroll mousedown DOMMouseScroll mousewheel keyup'); // This identifies the scroll as a user action, stops the animation, then unbinds the event straight after (optional)
    }

    function clearAllTimeout() {
        // clear all timeout
        var id = window.setTimeout(function() {}, 0);
        while (id--) {
            window.clearTimeout(id); // will do nothing if no timeout with id is present
        }
    }
    function handlePlay(e) {
        // Stop scroll
        setPlayDisabled(true)
        setTimeout(() => {
            setPlayDisabled(false)
            setPauseStatus(true)
            setPlayStatus(false)
            $('body,html').animate({
                scrollTop: $(document).height() - $(window).height()
            }, {
                duration: convertToMiliSecond(scrollSpeed),
                easing: "linear",
                
            });
        }, 1200);
    }

    function handleSerialChange(e,newValue) {
        // clearInterval(intervalId)

        treeList2 = treeList
        let treeSelected = treeList2.filter(tree => {
            return tree.serial == newValue
        })
        setSerialSelect(treeSelected[0])
        getData(treeSelected[0].treeid)

        // const intervalIdNext = setInterval(() => {
        //     console.log("setIntervalNEXT4 " + newValue)
        //     getData(nextTree)
        // }, MINUTE_MS)
        // setIntervalId(intervalIdNext)
    }

    function handleInputChange(e , field) {
        if (field == "scrollSpeed") {
            localStorage.setItem('speed', e.target.value)
            setScrollSpeed(e.target.value)
        } else if (field == "duration") {
            localStorage.setItem('duration', e.target.value)
            setDuration(e.target.value)
        }
    }

    function convertToMiliSecond(ms) {
        return ms * 1000
    }
      
    function handleBottom() {
        console.log("Duration to next " + duration)

        if (pauseStatus) {
            setLoading(true)
            setTimeout(() => {
                getData(treeData.next_tree)
            }, convertToMiliSecond(duration));
    
            $("html, body").animate({ scrollTop: 0 }, "slow")
        }
    }

    function formatMonth(datetime) {
        let month = datetime.split("-")[0]
        let year = datetime.split("-")[1]
        if (month == "01") {
            return "Jan - " + year
        } else if (month == "02") {
            return "Feb - " + year
        } else if (month == "03") {
            return "Mar - " + year
        } else if (month == "04") {
            return "Apr - " + year
        } else if (month == "05") {
            return "May - " + year
        } else if (month == "06") {
            return "Jun - " + year
        } else if (month == "07") {
            return "Jul - " + year
        } else if (month == "08") {
            return "Aug - " + year
        } else if (month == "09") {
            return "Sep - " + year
        } else if (month == "10") {
            return "Oct - " + year
        } else if (month == "11") {
            return "Nov - " + year
        } else if (month == "12") {
            return "Dec - " + year
        }
    }


    return (
        <div className="content-wrapper">

            <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Showcase</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item active">Showcase</li>
                        </ol>
                    </div>
                    </div>
                </div>


            </div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row d-flex justify-content-end mb-5" style={{position:"fixed", bottom: 0, right: 0, marginRight: "3rem", marginBottom: "3rem", zIndex: 1000}}>
                        <button className='btn btn-sm btn-outline-primary mr-2' data-toggle="modal" data-target="#modal-default">Setting</button>
                        { pauseStatus && (
                            <button className='btn btn-lg btn-success' onClick={(e)=>handlePause(e)}>PAUSE</button>
                        )}
                        { playStatus && (
                            <button className='btn btn-lg btn-success' disabled={playDisabled} onClick={(e)=>handlePlay(e)}>PLAY</button>
                        )}
                    </div>
                    <div className="row px-2 mb-3" style={{width:'100%', margin:'auto', justifyContent:'space-between'}}>
                        <button className='btn btn-primary' onClick={()=>{ handlePrevious() }}>
                            {"<"}
                        </button>
                        <div style={{width: "50%"}}>
                            <Autocomplete
                                className="col-12 col-sm-10"
                                disableClearable
                                disablePortal
                                options={treeList.map((option) => option.serial ? option.serial : "-")}
                                sx={{ width: "100%" }}
                                value={serialSelect.serial || ""}
                                onChange={(e, newValue)=>handleSerialChange(e, newValue)}
                                renderInput={(params) => <TextField {...params} label="Serial" />}
                            />
                        </div>
                        <button className='btn btn-primary' onClick={()=>{ handleNext() }}>
                            {">"}
                        </button>
                    </div>


                    <div className="row my-5" style={{ margin: "auto", display: "flex", textAlign: "center" }}>
                        <div className="col-12">
                            <h5>{treeData.breed ?? "-"} @ {treeData.farm ?? "-"}</h5>
                            <h5>Serial : {treeData.serial} </h5>
                            <h5>Ref : {treeData.ref ?? "-"} </h5>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12">
                            
                            <div className="timeline">
                                {timelineList.map( timeline => {
                                    return (
                                    <>
                                        <div className="time-label">
                                            <span className="bg-green">{formatMonth(timeline.datetime)}</span>
                                        </div>
                                        
                                        <div>
                                            <i className="fa fa-camera bg-purple"></i>
                                            <div className="timeline-item">
                                                {/* <span className="time"><i className="fas fa-clock"></i> 2 days ago</span> */}
                                                <h3 className="timeline-header">New photos uploaded</h3>
                                                <div className="timeline-body" style={{display:"flex", flexDirection:"row", gap:"1rem", flexWrap:"wrap"}}>
                                                    {timeline.image.map( (img, i) => {
                                                        return (
                                                            <img src={img} alt="..." style={{width: "16rem", height: "16rem"}} key={i}/>
                                                        )
                                                    })}

                                                    <div style={{padding: "1rem"}}>
                                                        <table className="table table-hover">
                                                            <thead>
                                                                <tr>
                                                                    <th></th>
                                                                    <th>Min</th>
                                                                    <th>Max</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td><b>Height</b></td>
                                                                    <td>{timeline.height}</td>
                                                                    <td>-</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>Diameter</b></td>
                                                                    <td>{timeline.diameter}</td>
                                                                    <td>-</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>pH</b></td>
                                                                    <td>{timeline.ph}</td>
                                                                    <td>-</td>
                                                                </tr>
                                                                <tr>
                                                                    <td><b>EC</b></td>
                                                                    <td>{timeline.sensor.soilec_min}</td>
                                                                    <td>{timeline.sensor.soilec_max}</td>
                                                                </tr>
                                                                {/* <tr>
                                                                    <td><b>pH</b></td>
                                                                    <td>{timeline.sensor.soilph_min}</td>
                                                                    <td>{timeline.sensor.soilph_max}</td>
                                                                </tr> */}
                                                                <tr>
                                                                    <td><b>wet</b></td>
                                                                    <td>{timeline.sensor.wet_min}</td>
                                                                    <td>{timeline.sensor.wet_max}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    )
                                })}
                                
                            </div>
                        </div>
                        
                    </div>
                    
                </div>
                
                <div className="modal fade" id="modal-default">
                    <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                        <h4 className="modal-title">Setting</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        </div>
                        <div className="modal-body">
                            <form className="form-horizontal">
                                <div className="card-body">
                                    {/* <div className="form-group row justify-content-center mb-5">
                                        <Autocomplete
                                            className="col-12 col-sm-10"
                                            disableClearable
                                            disablePortal
                                            options={treeList.map((option) => option.serial ? option.serial : "-")}
                                            sx={{ width: "100%" }}
                                            value={serialSelect.serial || ""}
                                            onChange={(e, newValue)=>handleSerialChange(e, newValue)}
                                            renderInput={(params) => <TextField {...params} label="Serial" />}
                                        />
                                    </div> */}
                                    <div className="form-group row">
                                        <label className="col-sm-2 col-form-label">Scroll Speed (s)</label>
                                        <div className="col-sm-10">
                                            <input type="number" className="form-control" placeholder="seconds" value={scrollSpeed} onChange={(e)=>handleInputChange(e, "scrollSpeed")}/>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <label className="col-sm-2 col-form-label">Duration to Next (s)</label>
                                        <div className="col-sm-10">
                                            <input type="number" className="form-control" placeholder="seconds" value={duration} onChange={(e)=>handleInputChange(e, "duration")}/>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                        {/* <div className="modal-footer justify-content-between">
                        <button type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary">Save changes</button>
                        </div> */}
                    </div>
                    </div>
                </div>
            </section>
            <BottomScrollListener onBottom={handleBottom} />
        </div>
    )
}

export default Showcase