import React, {useState} from 'react'
import { useParams } from 'react-router-dom'
import axiosInstance from '../../utils/api'
import { errorToast, successToast } from '../../utils/toast'

export default function Form() {
    const [loading, setLoading] = useState(false)
    const [images, setImages] = useState([]) 
    const [inputs, setInputs] = useState({
        description:'diameter',
        remark:'',
    })
    let { type, inspectionApplication, id, treeFarm, role } = useParams();

    function capitalize(string) {
        if (string && string.length > 0) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        } 
        return string
    }


    function displayImage(e) {
        if (e.target.files.length < 1) {
            return null
        }

        for (let i in e.target.files){
            if (Number.isInteger(parseInt(i))) {
                console.log(e.target.files[i])
                setImages(prev => [...prev, {
                    image_blob: URL.createObjectURL(e.target.files[i]),
                    image_file: e.target.files[i]
                }]);
            }
        }
    }

    function handleInput(e, field) {
        setInputs({
            ...inputs,
            [field]: e.target.value
        })
    }

    function submit() {
        let bodyFormData = new FormData();

       
        bodyFormData.append('', JSON.stringify(
            {
                "treeid": id,
                "group": inspectionApplication,
                "category": type,  
                "description": inputs.description, 
                "remark": inputs.remark
            }
        ));

        let counter = 1;
        for (let i in images){
            let file = new File([ images[i].image_file ],  new Date().toISOString() + "_" + counter, {type: "image/jpg", lastModified: Date.now()});
            bodyFormData.append(counter, file);
            counter ++
        }

        setLoading(true)
        axiosInstance.post(`${process.env.REACT_APP_API}/operations/create`, bodyFormData).then(res=>{
            setLoading(false)
            successToast('Success')
            return window.location.href= `/${role}/operations/${treeFarm}/${id}/activity`

        }).catch(err=>{
            console.log("err", err)
            setLoading(false)
            errorToast('Server Error')
        })
    }

    function typeBmTranslate(s) {
        switch (s) {
            case 'growth':
                return 'Pertumbuhan'
                break
            case 'deficiency':
                return 'Nutirisi'
                break
            case 'pest':
                return 'Perosak'
                break
            case 'disease':
                return 'Penyakit'
                break
            case 'fertilizer':
                return 'Baja'
                break
            case 'pesticide':
                return 'Racun Perosak'
                break
            case 'treatment':
                return 'Rawatan'
                break
            case 'herbicide':
                return 'Racun Rumput'
                break
            case 'fungicide':
                return 'Racun Kulat'
                break
            default:
                return 'undefined'
                break
        }
    }

    return (
        <div className="content-wrapper">

            <div class="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i><div class="text-bold pt-2"></div></div>
            </div>
            

            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1 className="m-0">{capitalize(inspectionApplication)}</h1>
                        </div>
                        {treeFarm == 'tree' ? (
                            <div className="col-sm-6">
                                {/* <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item"><a href={`/${role}/operations/scan-qr`}>Scan QR</a></li>
                                <li className="breadcrumb-item"><a href={`/${role}/operations/${treeFarm}/${id}/${(inspectionApplication)}`}>{capitalize(inspectionApplication)}</a></li>
                                <li className="breadcrumb-item active">Form</li>
                                </ol> */}
                            </div>
                        ) : (
                            <div className="col-sm-6">
                                {/* <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item"><a href={`/${role}/operations`}>Operations</a></li>
                                <li className="breadcrumb-item"><a href={`/${role}/operations/select-farms`}>Select Farms</a></li>
                                <li className="breadcrumb-item"><a href={`/${role}/operations/${treeFarm}/${id}/${(inspectionApplication)}`}>{capitalize(inspectionApplication)}</a></li>
                                <li className="breadcrumb-item active">Form</li>
                                </ol> */}
                            </div>
                        )}
                    </div>
                </div>
            </div>


            <section className="content">

                <div className="container-fluid">


                    <div className="row">
                        <div className="col-12">
                            <div class="text-center p-3">
                                <h4>{capitalize(type)}</h4>
                                <h4>({typeBmTranslate(type)})</h4><br/>
                                
                                <div class="row justify-content-center">
                                {images.length > 0 ? (
                                    images.map((image) => (
                                        <div className="col-5 col-md-3 m-2 bg-black" >
                                            <div style={{position:'absolute'}}>
                                                <button onClick={()=>{
                                                    setImages( prev => 
                                                        prev.filter( img => {
                                                            return img.image_blob != image.image_blob
                                                        })
                                                    )
                                                }}>x</button>
                                            </div>

                                            <a href={image.image_blob} data-toggle="lightbox" data-title="Image" data-farm="farm" >
                                                <img src={image.image_blob} className="img-fluid" style={{height:'200px'}} alt="Farmsens-Image"/>
                                            </a>
                                        </div>
                                    ))
                                ) : (
                                    <div>
                                        <p>No Image Uploaded</p>
                                    </div>
                                )}
                                </div>

                                <div class="row justify-content-center">
                                    <button className="btn btn-primary w-100 my-4 px-4 col-12 col-md-3">
                                        <label htmlFor="img" style={{margin:0}}>
                                            Add Image
                                            <input
                                                id="img"
                                                multiple
                                                name="img"
                                                type="file"
                                                accept="image/*;capture=camera"
                                                onChange={displayImage}
                                                hidden
                                            />
                                        </label>
                                    </button>
                                </div>
                                {/* Remark NUMERIC only for category=growth & type=application */}
                                {(type=='growth') ? (
                                    <>
                                    <div class="form-group row my-4 px-4">
                                        <label className="col-12 col-sm-2 col-form-label">Description</label>
                                        <select className="col-12 col-sm-10 form-control" onChange={e=>handleInput(e, 'description')}>
                                            <option value="diameter">Diameter</option>
                                            <option value="height">Height</option>
                                        </select>
                                    </div>
                                    
                                    <div class="form-group row my-4 px-4">
                                        <label className="col-12 col-sm-2 col-form-label">Remark</label>
                                        <div className="col-12 col-sm-10">
                                            <input className="form-control" rows="3" type="number" 
                                            placeholder={inputs.description=='height'? "m" : "mm"}
                                            onKeyDown={e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()} 
                                            onChange={e=>handleInput(e, 'remark')}></input>
                                        </div>
                                    </div>
                                    </>
                                ): (inspectionApplication=='application' ? (
                                    <>
                                    <div class="form-group row my-4 px-4">
                                        <label className="col-12 col-sm-2 col-form-label">Description</label>
                                        <div className="col-12 col-sm-10">
                                            <input className="form-control" rows="3" placeholder="Enter ..." onChange={e=>handleInput(e, 'description')}/>
                                        </div>
                                    </div>
                                    
                                    <div class="form-group row my-4 px-4">
                                        <label className="col-12 col-sm-2 col-form-label">Remark</label>
                                        <div className="col-12 col-sm-10">
                                            <input className="form-control" rows="3" type="number" 
                                            onChange={e=>handleInput(e, 'remark')}
                                            onKeyDown={e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()} 
                                            placeholder={(inspectionApplication=='application') ? (type=='fertilizer' ? 'kg' : 'ml') : 'Enter ...'}/>
                                        </div>
                                    </div>
                                    </>
                                ) : (
                                        <>
                                        <div class="form-group row my-4 px-4">
                                            <label className="col-12 col-sm-2 col-form-label">Description</label>
                                            <div className="col-12 col-sm-10">
                                                <input className="form-control" rows="3" placeholder="Enter ..." onChange={e=>handleInput(e, 'description')}/>
                                            </div>
                                        </div>
                                        
                                        <div class="form-group row my-4 px-4">
                                            <label className="col-12 col-sm-2 col-form-label">Remark</label>
                                            <div className="col-12 col-sm-10">
                                                <input className="form-control" rows="3"
                                                onChange={e=>handleInput(e, 'remark')}
                                                placeholder={(inspectionApplication=='application') ? (type=='fertilizer' ? 'kg' : 'ml') : 'Enter ...'}/>
                                            </div>
                                        </div>
                                        </>
                                    )
                                )}
                                
                                
                                <div class="row my-5 px-4 justify-content-center">
                                    <button class="btn btn-success w-100 col-12 col-md-3" href="#" onClick={submit}>
                                    Submit
                                    </button>
                                </div>

                                <div class="row my-2 px-4 justify-content-center">
                                    <a class="btn btn-warning w-100 col-12 col-md-3" href={`/${role}/operations/${treeFarm}/${id}/${inspectionApplication}`}>
                                    Back
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                
                
                
                </div>
            </section>


        </div>
    )
}
