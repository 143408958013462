import React, {useEffect,useState} from 'react'
import axios from 'axios'
import { config } from '../../../utils/config';
import { useParams } from 'react-router-dom'
import { successToast, errorToast } from '../../../utils/toast';
import  axiosInstance  from '../../../utils/api'
import AddImageModal from '../components/AddImageModal';

export default function FarmAddEdit() {
    const [loading, setLoading] = useState(false)

    const [type, setType] = useState('add')
    let { farmId, role } = useParams();
    const [farmData, setFarmData] = useState({
        farm: {},
        url:[]
    })

    const [treesOptions, setTreesOptions] = useState({
        available_tree:[],
        farm_tree:[]
    })

    useEffect(() => {
        if (farmId) {
            setType('edit')
            getFarmData(farmId)
        }
        getTrees()
    }, [])

    function getTrees() {
        let url
        if (farmId) {
            url = `${process.env.REACT_APP_API}/farms/get-trees?farmid=${farmId}`
        } else {
            url = `${process.env.REACT_APP_API}/farms/get-trees`
        }
        axiosInstance.get(url)
        .then(res=>{
            console.log(res.data)
            setTreesOptions({
                available_tree: res.data.available_tree,
                farm_tree: res.data.farm_tree,
            })
        }).catch(res=>{
            setLoading(false)
            console.log(res)

        })
           
    }

    function getFarmData(id) {
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/farms/show/${id}`)
        .then(res=>{
            setLoading(false)
            setFarmData({
                farm: res.data
            })

        }).catch(res=>{
            setLoading(false)
            console.log(res)
            errorToast('Server Error')
        })
    }

    function handleSubmit(e) {
        e.preventDefault()

        if (type=='add') {
            if (!farmData.farm.name) {
                return errorToast('Name is required')
            }
            createFolder()
        } else if (type=='edit') {
            updateFolder()
        }

    }

    function createFolder() {
        setLoading(true)
        axiosInstance.post(`${process.env.REACT_APP_API}/farms/create`,  farmData.farm).then(res=>{
            setLoading(false)
            successToast(res.data.message)
            return window.location = '/'+role+'/farms'
        }).catch( res => {
            setLoading(false)
            errorToast('Server Error')
        })
    }

    function updateFolder() {
        console.log(22 ,farmData.farm)
        setLoading(true)
        axiosInstance.post(`${process.env.REACT_APP_API}/farms/update/${farmId}`, farmData.farm).then(res=>{
            setLoading(false)
            successToast(res.data.message)
            return window.location = '/'+role+'/farms'
        }).catch( res => {
            setLoading(false)
            errorToast('Server Error')
        })
    }


    function handleInput(e, field) {
        if (field == 'remove_tree' || field == 'add_tree') {
            let val = [...e.target.selectedOptions].map(opt => opt.value)
            setFarmData( prev => ({
                ...prev,
                farm: {
                    ...prev.farm,
                    [field]: val
                }
            }))
        } else {
            setFarmData( prev => ({
                ...prev,
                farm: {
                    ...prev.farm,
                    [field]:e.target.value
                }
            }))
        }
    }

    return (
        <div className="content-wrapper">

            <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">{type=='add'?('Create'):('Update')} Farm</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href={`/${role}`}>Home</a></li>
                        <li className="breadcrumb-item"><a href={`/${role}/farms`}>Farm</a></li>
                        <li className="breadcrumb-item active">{type=='add'?('Create'):('Update')}</li>
                        </ol>
                    </div>
                    </div>
                </div>


            </div>

            <section className="content">

                <div className="container-fluid">


                    <div className="row">
                        <div className="col-md-12">
                            <form className="form-horizontal"  onSubmit={handleSubmit}>
                                <div className="card card-primary card-outline">
                                    <div className="card-header">
                                        <h3 className="card-title">Farm Info</h3>
                                    </div>

                                        <div className="card-body">
                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Farm Name<span style={{color:'red'}}>*</span></label>
                                                <div className="col-sm-10">
                                                    <input value={farmData.farm.name ||''} onChange={(e)=>handleInput(e,'name')} type="text" className="form-control" id="library-name" placeholder="Required" disabled={type=='view'?(true):(false)}/>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Address</label>
                                                <div className="col-sm-10">
                                                    <input value={farmData.farm.address ||''} onChange={(e)=>handleInput(e,'address')} type="text" className="form-control" id="breed" placeholder="Optional" disabled={type=='view'?(true):(false)}/>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Latitude</label>
                                                <div className="col-sm-10">
                                                    <input value={farmData.farm.latitude ||''} onChange={(e)=>handleInput(e,'latitude')} type="text" className="form-control" id="breed-other-name" placeholder="Optional" disabled={type=='view'?(true):(false)}/>
                                                </div>
                                            </div>
                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Longitude</label>
                                                <div className="col-sm-10">
                                                    <input value={farmData.farm.longitude ||''} onChange={(e)=>handleInput(e,'longitude')}  type="text" className="form-control" id="grade" placeholder="Optional" disabled={type=='view'?(true):(false)}/>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Size</label>
                                                <div className="col-sm-10">
                                                    <input value={farmData.farm.size ||''} onChange={(e)=>handleInput(e,'size')}  type="text" className="form-control" id="grade" placeholder="Optional (acre)" disabled={type=='view'?(true):(false)}/>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">No of Trees</label>
                                                <div className="col-sm-10">
                                                    <input value={farmData.farm.no_tree ||''} onChange={(e)=>handleInput(e,'no_tree')}  type="text" className="form-control" id="grade" placeholder="Optional" disabled={type=='view'?(true):(false)}/>
                                                </div>
                                            </div>

                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Story</label>
                                                <div className="col-sm-10">
                                                    <input value={farmData.farm.story ||''} onChange={(e)=>handleInput(e,'story')}  type="text" className="form-control" id="grade" placeholder="Optional" disabled={type=='view'?(true):(false)}/>
                                                </div>
                                            </div>

                                            
                                            <div className="form-group row">
                                                <label>Add Trees</label>
                                                <select multiple class="form-control" onChange={e => handleInput(e,'add_tree')}>
                                                    {treesOptions.available_tree.length > 0 ? (
                                                        treesOptions.available_tree.map(t => {
                                                            if (t.serial) {
                                                                return (
                                                                    <option key={t.treeid} value={t.treeid}>{t.serial} - {t.breed}</option>
                                                                )
                                                            } else {
                                                                return (
                                                                    <option key={t.treeid} value={t.treeid}>-</option>
                                                                )
                                                            }
                                                        })
                                                    ) : (
                                                        <option>No Data</option>
                                                    )}
                                                </select>
                                            </div>

                                            {type=='edit' && (
                                                <div className="form-group row">
                                                    <label>Remove Trees</label>
                                                    <select multiple class="form-control" onChange={e => handleInput(e,'remove_tree')}>
                                                        {treesOptions.farm_tree.length > 0 ? (
                                                            treesOptions.farm_tree.map(t => {
                                                                if (t.serial) {
                                                                    return (
                                                                        <option key={t.treeid} value={t.treeid}>{t.serial} - {t.breed}</option>
                                                                    )
                                                                } else {
                                                                    return (
                                                                        <option key={t.treeid} value={t.treeid}>-</option>
                                                                    )
                                                                }
                                                            })
                                                        ) : (
                                                            <option disabled>No Data</option>
                                                        )}
                                                    </select>
                                                </div>
                                            )}
                                        </div>

                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-primary" >{type=='add'?('Submit'):('Update')}</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                    <AddImageModal  data={farmData} setLoading={setLoading} />

                </div>
            </section>
        </div>
    )
}
