import React, {useEffect,useState} from 'react'
import axios from 'axios'
import { config } from '../../../utils/config';
import { useLocation,useParams } from 'react-router-dom'
import { successToast, errorToast } from '../../../utils/toast';
import  axiosInstance  from '../../../utils/api'
import ChangePasswordModal from '../components/ChangePasswordModal';

export default function UserAddEdit() {
    const [loading, setLoading] = useState(false)
    const [type, setType] = useState('add')
    let { username, role } = useParams();
    const [changePasswordModal, setChangePasswordModal] = useState(false)

    const [inputs, setInputs] = useState({
        username:'',
        mobile:'',
        is_staff: true,
        is_active: true,
        telegramid: '',
        last_name: '',
        first_name: '',
        role:'worker',
        // password: ''
    })

    useEffect(() => {
        if (username) {
            setType('edit')
            getUserData(username)
        } else {
            setType('add')
        }
    }, [])

    function getUserData(u) {
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/users/show/${u}/`)
        .then(res=>{
            setLoading(false)
            setInputs(res.data)
        }).catch(res=>{
            setLoading(false)
            errorToast('Server Error')
        })
    }

    function handleInput(e, field) {
        if (field=='username') {
            setInputs({
                ...inputs,
                [field]: e.target.value.toLowerCase()
            })
        } else {
            setInputs({
                ...inputs,
                [field]: e.target.value
            })
        }
    }

    function handleSubmit() {
        if (type == 'edit') {
            setLoading(true)
            axiosInstance.post(`${process.env.REACT_APP_API}/users/update/${username}`, inputs).then(res=>{
                setLoading(false)
                successToast('Succeesfully Updated')
                getUserData(username)
            }).catch(res=>{
                setLoading(false)
                errorToast('Server Error')
            })
        } else if (type=='add') {
            console.log(inputs)
            if (inputs.username == '' || typeof inputs.username == 'undefined' || inputs.password == '' || typeof inputs.password == 'undefined' ) {
                return errorToast('Please fill in username and password')
            }
            setLoading(true)
            axiosInstance.post(`${process.env.REACT_APP_API}/users/create`, inputs).then(res=>{
                setLoading(false)
                successToast('Succeesfully Created')
                return window.location = '/users/'
            }).catch(res=>{
                setLoading(false)
                errorToast('Server Error')
            })
        }
    }

    return (
        <div className="content-wrapper">

            <div class="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i><div class="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">User Detail</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href={`/${role}`}>Home</a></li>
                            <li className="breadcrumb-item"><a href={`/${role}/users`}>User</a></li>
                            <li className="breadcrumb-item active">User Detail</li>
                        </ol>
                    </div>
                    </div>
                </div>
            </div>

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        
                        <div className="col-md-12">
                            <div className="card card-primary card-outline">
                                <div className="card-header">
                                    <h3 className="card-title">User Info</h3>
                                </div>

                                <form className="form-horizontal">
                                    <div className="card-body">
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Username</label>
                                            <div className="col-sm-10">
                                                <input value={inputs.username ||''} onChange={(e)=>handleInput(e,'username')} type="text" className="form-control" id="library-name" placeholder="Required" disabled={type=='add'?false:true}/>
                                            </div>
                                        </div>
                                        {type=='add' && (
                                            <div className="form-group row">
                                                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Password</label>
                                                <div className="col-sm-10">
                                                    <input value={inputs.password ||''} onChange={(e)=>handleInput(e,'password')} type="password" className="form-control" id="library-name" placeholder="Required" />
                                                </div>
                                            </div>
                                        )}
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">First Name</label>
                                            <div className="col-sm-10">
                                                <input value={inputs.first_name ||''} onChange={(e)=>handleInput(e,'first_name')} type="text" className="form-control" id="library-name"  />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Last Name</label>
                                            <div className="col-sm-10">
                                                <input value={inputs.last_name ||''} onChange={(e)=>handleInput(e,'last_name')} type="text" className="form-control" id="library-name"  />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Mobile</label>
                                            <div className="col-sm-10">
                                                <input value={inputs.mobile ||''} onChange={(e)=>handleInput(e,'mobile')} type="number" className="form-control" id="library-name"  />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Telegram ID</label>
                                            <div className="col-sm-10">
                                                <input value={inputs.telegramid ||''} onChange={(e)=>handleInput(e,'telegramid')} type="text" className="form-control" id="library-name"  />
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Active</label>
                                            <div className="col-sm-10">
                                                <select class="form-control" value={inputs.is_active} onChange={e => handleInput(e,'is_active')}>
                                                    <option value="true">Active</option>
                                                    <option value="false">Inactive</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Role</label>
                                            <div className="col-sm-10">
                                                <select class="form-control" value={inputs.role} onChange={e => handleInput(e,'role')}>
                                                    <option value="worker">Worker</option>
                                                    <option value="admin">Admin</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>

                    <div className="row p-3">
                        <button type="submit" className="btn btn-primary btn-md mx-2" onClick={handleSubmit}>Update</button>
                        {type=='edit' && (
                        <button className="btn btn-secondary btn-md mx-2" onClick={()=>{setChangePasswordModal(true)}}>Change Password</button>
                        )}
                    </div>
                </div>
                
                <ChangePasswordModal changePasswordModal={changePasswordModal} setChangePasswordModal={setChangePasswordModal} setLoading={setLoading} username={username} getUserData={getUserData}/>
            </section>

            
        </div>
    )
}
