import React, {useEffect,useState} from 'react'
import { useParams } from 'react-router-dom'
import  axiosInstance  from '../../../utils/api'

export default function FarmTree() {
    const [loading, setLoading] = useState(false)
    let { farmId, role } = useParams();
    const [trees, setTrees] = useState([])

    useEffect(() => {
        getTrees()
    }, [])

    function getTrees() {
        setLoading(true)
        let url
        if (farmId) {
            url = `${process.env.REACT_APP_API}/farms/get-trees?farmid=${farmId}`
        } 
        axiosInstance.get(url)
        .then(res=>{
            setLoading(false)
            setTrees(res.data.farm_tree)
        }).catch(res=>{
            setLoading(false)
            console.log(res)
        })
    }


    return (
        <div className="content-wrapper">

            <div class="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i><div class="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Farm Tree List</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item active">Farm Tree List</li>
                        </ol>
                    </div>
                    </div>
                </div>


            </div>

            <section className="content">
                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title"></h3>
                                </div>
                                <div className="card-body table-responsive p-0">
                                    <table className="table table-hover text-nowrap">
                                        <thead>
                                            <tr>
                                                <th>Serial</th>
                                                <th>Breed</th>
                                                <th>Year</th>
                                                <th>Age</th>
                                                <th>Height</th>
                                                <th>Diameter</th>
                                                <th>Categories</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {trees.length > 0 ? 
                                                trees.map( tree => (
                                                    <tr>
                                                        <td>
                                                            <a href={`/${role}/trees/${tree.treeid}/view`}>{tree.serial}</a>
                                                        </td>
                                                        <td>{tree.breed ? tree.breed : '-'}</td>
                                                        <td>{tree.year}</td>
                                                        <td>{tree.age}</td>
                                                        <td>{tree.height}</td>
                                                        <td>{tree.diameter}</td>
                                                        <td>{tree.farm}</td>
                                                    </tr>
                                            )) : (
                                            <tr>
                                                <td>No Data</td>
                                            </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>

                                {/* <div class="card-footer clearfix">
                                    <ul class="pagination pagination-sm m-0 float-right">
                                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                
                
                </div>
            </section>
            
        </div>
    )
}
