import React, {useEffect,useState, useMemo} from 'react'
import { errorToast, successToast } from '../../utils/toast'
import axiosInstance from '../../utils/api'
import DataTable from 'react-data-table-component';
import {Link, useParams} from "react-router-dom";

export default function SelectTrees() {
    const [loading, setLoading] = useState(false)
    const [trees, setTress] = useState([])
    let { role } = useParams();

    useEffect(() => {
        getData()
    }, [])

    
    const columns = [
        {
            name: 'Serial',
            selector: row => row.treeid,
            cell: (row, index, column, id) => {
                return <Link to={`/${role}/operations/tree/${row.treeid}/activity`}>{row.serial ? row.serial : '-'}</Link>
            },
            sortable: true,
        },
        {
            name: 'Breed',
            selector: row => row.breed,
            sortable: true,
        },
        {
            name: 'Year',
            selector: row => row.year,
            sortable: true,
        },
        {
            name: 'Height (ft)',
            selector: row => row.height,
            sortable: true,
        },
        {
            name: 'Diameter (mm)',
            selector: row => row.diameter,
            sortable: true,
        },
        {
            name: 'Latitude',
            selector: row => row.latitude,
            sortable: true,
        },
        {
            name: 'Longitude',
            selector: row => row.longitude,
            sortable: true,
        },
        {
            name: 'Categories',
            selector: row => row.farm,
            sortable: true,
        }
    ];

    const getData = () => {
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/trees`).then(res=> {
            setLoading(false)
            console.log(res.data)
            setTress(res.data)
        }).catch(res=>{
            setLoading(false)
            console.log(res)
            errorToast('Server Error')
        })
    }

    
    const [filterSerial, setFilterSerial] = useState('');
    const [filterBreed, setFilterBreed] = useState('');
    const [filterYear, setFilterYear] = useState('');
    const [filterCategory, setFilterCategory] = useState('');
    const filteredItems = trees.filter(
		item => {
            let year = ""
            if (item.year) {
                year = item.year
            }

            if ( (item.breed && item.breed.toLowerCase().includes(filterBreed.toLowerCase())) && 
                (item.serial && item.serial.toLowerCase().includes(filterSerial.toLowerCase())) && 
                (year.toLowerCase().includes(filterYear.toLowerCase())) && 
                (item.farm && item.farm.toLowerCase().includes(filterCategory.toLowerCase()))
            ) {
                return item
            }
        }
	);
    const subHeaderComponentMemo = useMemo(() => {
		return (
            <>
            <input value={filterSerial} onChange={e=>{setFilterSerial(e.target.value)}} placeholder="Search Serial" className='mr-2'/>
            <input value={filterBreed} onChange={e=>{setFilterBreed(e.target.value)}} placeholder="Search Breed" className='mr-2'/>
            <input value={filterYear} onChange={e=>{setFilterYear(e.target.value)}} placeholder="Search Year" className='mr-2'/>
            <input value={filterCategory} onChange={e=>{setFilterCategory(e.target.value)}} placeholder="Search Category"/>
            </>
		);
	}, [filterSerial, filterBreed, filterYear, filterCategory]);


    return (
        <div className="content-wrapper">

            <div class="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i><div class="text-bold pt-2"></div></div>
            </div>
            
            {/* <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Select Trees</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item"><a href={`/${role}/operations`}>Operations</a></li>
                        <li className="breadcrumb-item active">Select Trees</li>
                        </ol>
                    </div>
                    </div>
                </div>
            </div> */}

            <section className="content">

                <div className="container-fluid">

                    

                <div className="row">
                        <div className="col-12">
                            
                        <div className="card my-4">
                                <div className="card-body table-responsive p-0">
                                <DataTable
                                        columns={columns}
                                        data={filteredItems}
                                        pagination
                                        paginationComponentOptions={{
                                            noRowsPerPage:true
                                        }}
                                        subHeader
                                        subHeaderComponent={subHeaderComponentMemo}
                                    />
                                </div>
                            </div>

                                
                                
                            <div className="row justify-content-center">
                                <div class=" col-12 col-md-3">
                                    <a class="btn btn-warning w-100" href={`/${role}/operations`}>
                                    Back
                                    </a>
                                </div>
                            </div>
                                
                        </div>
                    </div>
                
                </div>
            </section>


            
        </div>
    )
}
