import axios from 'axios'
import React, {useState,useEffect} from 'react'
import { Redirect } from 'react-router-dom'
import { errorToast } from '../../utils/toast'

export default function Login() {
    const [loading, setLoading] = useState(false);
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

	const [isAuthenticated, setIsAuthenticated] = useState(false)

    useEffect(() => {
        if (localStorage.getItem('token:access')) {
            setIsAuthenticated(true)
            if (localStorage.getItem('user:data') && JSON.parse(localStorage.getItem('user:data'))) {
                let user_role = JSON.parse(localStorage.getItem('user:data')).role
                if (user_role == 'admin') {
                    return window.location.href = '/admin'
                } else {
                    return window.location.href = '/worker/operations'
                }
            }
        } else {
            setIsAuthenticated(false)
        }
    }, [])

    function handleSubmit(e) {
        e.preventDefault()
        setLoading(true)

        axios.post(`${process.env.REACT_APP_API}/token/`, {
            username: username,
            password: password
        }).then(res=>{
            setLoading(false)
            localStorage.setItem('token:access', res.data.access);
            localStorage.setItem('token:refresh', res.data.refresh);
            localStorage.setItem('user:data', JSON.stringify({
                "username": res.data.username,
                "first_name": res.data.first_name,
                // "company": res.data.company,
                "role": res.data.role,
            }));

            if (res.data.role=='worker') {
                return window.location.href = '/worker/operations'
            } else {
                    return window.location.href = '/admin'
            }
        }).catch(res=>{
            setLoading(false)
            if (res.response.data) {
                errorToast(res.response.data.detail)
            }
        })
        
    }

    function handleChange(e, type) {
        switch(type) {
            case 'username':
                setUsername(e.target.value)
                break;
            case 'password':
                setPassword(e.target.value)
                break;
            default:
                break;
        }
    }
    return (

        <div class="hold-transition login-page">
            <div class="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i><div class="text-bold pt-2"></div></div>
            </div>
            <div class="login-box">
                <div class="login-logo">
                    <img src="/img/matang-icon.jfif" />
                </div>
                <div class="card">
                        <div class="card-body login-card-body">
                            <p class="login-box-msg">Sign in to start your session</p>
                    
                            <form onSubmit={e=>handleSubmit(e)} method="post">
                                <div class="input-group mb-3">
                                <input type="text" class="form-control" placeholder="Username"  value={username} onChange={(e)=>handleChange(e, 'username')}/>
                                <div class="input-group-append">
                                    <div class="input-group-text">
                                    <span class="fas fa-user"></span>
                                    </div>
                                </div>
                                </div>
                                <div class="input-group mb-3">
                                <input type="password" class="form-control" value={password} onChange={(e)=>handleChange(e, 'password')}/>
                                <div class="input-group-append">
                                    <div class="input-group-text">
                                    <span class="fas fa-lock"></span>
                                    </div>
                                </div>
                                </div>
                                <div class="row">
                                <div class="col-12">
                                    <button type="submit" class="btn btn-primary btn-block">Sign In</button>
                                </div>
                                </div>
                            </form>
                    
                        </div>
                </div>
            </div>
        </div>
    )
}
