export function successToast(message) {
    return window.$(document).Toasts('create', {
        class: 'bg-success',
        title: 'Success',
        autohide: true,
        icon: 'fas fa-check',
        delay: 1500,
        body: message
    })
}

export function errorToast(message) {
    return window.$(document).Toasts('create', {
        class: 'bg-danger',
        title: 'Failed',
        autohide: true,
        icon: 'far fa-times-circle',
        delay: 1500,
        body: message
    })
}