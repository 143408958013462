import React, {useEffect, useState} from 'react'
import axiosInstance from '../utils/api'
import { errorToast, successToast } from '../utils/toast'

export default function SideNav(props) {
    const {role} = props

    let userData = localStorage.getItem('user:data')
    const [user, setUser] = useState({
        username: '',
        first_name:'',
        company: ''
    })

    const [farms, setFarms] = useState([])
    const [show, setShow] = useState({
        chart: false
    })

    useEffect(() => {
        console.log(JSON.parse(userData))
        if (JSON.parse(userData)) {
            setUser({
                username: JSON.parse(userData).username,
                first_name: JSON.parse(userData).first_name,
                company: JSON.parse(userData).company
            })
        }
        if (role =='admin') {
            getFarms()
        }
    }, [userData])

    function truncate(str, n){
        return str
        // if (str!='' || typeof str != 'undefined') {
        //     return (str.length > n) ? str.substr(0, n-1) + '...' : str;
        // }
    };

    function getFarms() {
        axiosInstance.get(`${process.env.REACT_APP_API}/farms/`).then(res=>{
            setFarms(res.data)
        }).catch(res=>{
            console.log(res)
            errorToast('Server Error')
        })
    }
    
    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <a href="#" className="brand-link" style={{display:'flex',}}>
                <div className="col-4" style={{alignSelf:'center'}}>
                    <img src="/img/matang-icon.jfif" alt="Matang Logo" className="brand-image" style={{opacity: 0.8}}/>
                    <h5>Matang Berhad</h5>
                </div>
                <div className="col-8" style={{whiteSpace:'normal', lineHeight: '21px', fontSize:'20px'}}>
                <span className="brand-text font-weight-light">{truncate(user.company, 24)}</span>
                </div>
            </a>

            <div className="sidebar">
                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                    <div className="image">
                    <img src="/img/user-icon.png" className="img-circle elevation-2 bg-white" alt="User Image"/>
                    </div>
                    <div className="info">
                    <a href="#" className="d-block">{user.first_name} {(user.first_name && user.username) && (<> / </>) } {user.username}</a>
                    </div>
                </div>
                
                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">

                        {role == 'admin' && (
                        <li className="nav-item">
                            <a href={`/${role}`} className="nav-link">
                                <i className="nav-icon fas fa-tachometer-alt"></i>
                                <p>Dashboard</p>
                            </a>
                        </li>
                        )}

                        <li className="nav-item">
                            <a href={`/${role}/operations`} className="nav-link">
                                <i className="nav-icon fas fa-chart-pie"></i>
                                <p>Operation</p>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href={`/${role}/input`} className="nav-link">
                                <i className="nav-icon fas fa-plus"></i>
                                <p>Input</p>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href={`/${role}/showcase`} className="nav-link">
                                <i className="nav-icon fas fa-globe-asia"></i>
                                <p>Showcase</p>
                            </a>
                        </li>
                        {role == 'admin' && (
                        <>
                        <li className="nav-item">
                            <a href={`/${role}/farms`} className="nav-link">
                                <i className="nav-icon fas fa-tractor"></i>
                                <p>Farm </p>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href={`/${role}/trees`} className="nav-link">
                                <i className="nav-icon fas fa-tree"></i>
                                <p>Trees</p>
                            </a>
                        </li>
                        <li className="nav-item">
                            <a href="#" className="nav-link" onClick={()=>setShow({chart: !show.chart})}>
                            <i className="nav-icon fas fa-chart-pie"></i>
                            <p>
                                Charts
                                <i className="right fas fa-angle-left"></i>
                            </p>
                            </a>
                            <ul className="nav" style={show.chart ? {display: 'block'} : {display: 'none'} }>
                                <li className="nav-item">
                                    <a href={`/${role}/daily-charts`} className="nav-link">
                                    <i className="far fa-circle nav-icon"></i>
                                    <p>Daily</p>
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href={`/${role}/monthly-charts`} className="nav-link">
                                    <i className="far fa-circle nav-icon"></i>
                                    <p>Monthly</p>
                                    </a>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <a href={`/${role}/users`} className="nav-link">
                            <i className="nav-icon fas fa-user"></i>
                            <p>User</p>
                            </a>
                        </li>
                        </>
                        )}
                        <li className="nav-item">
                            <a href="/" className="nav-link" onClick={()=>{
                                localStorage.clear()
                                window.location.reload()
                            }}>
                                <i className="nav-icon fas fa-sign-out-alt"></i>
                                <p>Logout</p>
                            </a>
                        </li>

                        {role == 'admin' && (
                            <li className="nav-header">Categories</li>
                        )}
                        {farms.length > 0 && farms.map(farm => {
                            return (
                                <li className="nav-item" key={farm.farmid}>
                                    <a href={`/${role}/farm-tree/${farm.farmid}`} className="nav-link">
                                    <i className="nav-icon fas fa-map-marker-alt"></i>
                                    <p>{farm.name}</p>
                                    </a>
                                </li>
                            )
                        })}
                    </ul>
                </nav>
            </div>
        </aside>
    )
}
