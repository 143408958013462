import React, { useEffect, useState } from 'react'
import { Route, Redirect } from "react-router-dom";
import Error404 from '../pages/errors/Error404';

export const WorkerRoute = (props) => {
    const {path, match, component: Component, render, ...rest} = props;
	const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('token:access'))
	const [role, setRole] = useState('worker')

    useEffect(() => {
        if (localStorage.getItem('token:access')) {
            setIsAuthenticated(true)

            if (localStorage.getItem('user:data') && JSON.parse(localStorage.getItem('user:data'))) {
                let user_role = JSON.parse(localStorage.getItem('user:data')).role
                if (user_role == 'admin') {
                    setRole('admin')
                    console.log('WorkerRoute Admin')
                } else {
                    setRole('worker')
                    console.log('WorkerRoute Worker')
                }
            }
        } else {
            setIsAuthenticated(false)
        }
    }, [])

    if (role != 'worker') {
        return <Error404/>
    } else {
        return (
            <Route
            {...rest}
            path={`${path}`}
            render={(routerProps) => {
                if (isAuthenticated) {
                    return  Component? <Component {...rest} {...routerProps} /> : render(routerProps)
                } else {
                    return (
                        <Redirect
                        to={{
                            pathname: "/",
                        }}
                        />
                    );
                }
            }}
            />
        )
    }
}
