import './App.css';
import React, { useEffect, useState } from 'react'

import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Login from './pages/auth/Login';
import Admin from './routes/Admin';
import Worker from './routes/Worker';
import Error404 from './pages/errors/Error404';
import Showcase from './pages/showcase/Showcase';


// // admin routes
// const Admin = (props) => (
// 	<React.Fragment>
// 		<AdminRoute {...props} path={ROUTES.NEWBLOG} component={NewBlog} />
// 		<AdminRoute {...props} path='/2' render={() => <h2>test</h2>} />
// 	</React.Fragment>
// );

// // authenticated user routes
// const Worker = (props) => (
// 	<React.Fragment>
// 	  <Switch>
// 		<WorkerRoute {...props} path={'/worker'} render={() => <h2>one</h2>} />
// 	  </Switch>
// 	</React.Fragment>
// );

// // public routes
// const Public = ({ match }) => (
// 	<React.Fragment>
// 	  <Switch>
// 		<Route path={`${match.path}/5`} render={() => <h2>one</h2>} />
// 		<Route path={`${match.path}/6`} render={() => <h2>two</h2>} />
// 	  </Switch>
// 	</React.Fragment>
// );

function App() {
	const [role, setRole] = useState('')
	const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('token:access'))
	const [treeId, setTreeId] = useState('')
	useEffect(() => {
		if (window.location.pathname.split('/')[1] == 'tree') {
			if (localStorage.getItem('token:access')) {
				setIsAuthenticated(true)
				if (localStorage.getItem('user:data') && JSON.parse(localStorage.getItem('user:data'))) {
					let user_role = JSON.parse(localStorage.getItem('user:data')).role
					if (user_role == 'admin') {
						setRole('admin')
						setTreeId(window.location.pathname.split('/')[2])
					} else {
						setRole('worker')
						setTreeId(window.location.pathname.split('/')[2])
					}
				}
			} else {
				setIsAuthenticated(false)
			}
		}
	}, [])

	useEffect(() => {
		if (treeId != '') {
			return window.location.href = `/${role}/operations/tree/${treeId}/activity`
		}
	}, [treeId])
	
    if (!isAuthenticated) {
        // if /showcase then just pass
        if (!window.location.pathname.includes("showcase")) {
            return <Login />
        }
    }

	return (
		<>
		<Router>
			<Switch>
				<Route path="/admin" component={Admin} />
				<Route path="/worker" component={Worker} />
                <Route path="/showcase" component={Showcase} />
						
				<Route path="/">
					<Login />
				</Route>

				
				<Route path="*" >
					<Error404 />
				</Route>

				{/* <Route path="/login" component={Public} /> */}

				{/*  {role == 'admin' ? (
					<ProtectedRoute exact path={`/`} component={Admin} />
				):(
					<ProtectedRoute exact path={`/worker`} component={Worker} />
				)} */}
			</Switch>
		</Router>

		</>
	);
}

export default App;
