import React, {useEffect,useState} from 'react'
import  axiosInstance  from '../../../utils/api'
import { errorToast, successToast } from '../../../utils/toast';

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

let recommendation_data = [
    {
        category: '2020',
        likely: 3,
        unlikely: 1,
    },
    {
        category: '2021',
        likely: 6,
        unlikely: 8,
    },
];

let general_data = [
    {
        category: '2020',
        1: 3,
        2: 1,
        3: 1,
        4: 1,
        5: 1,
    },
    {
        category: '2021',
        1: 3,
        2: 1,
        3: 1,
        4: 1,
        5: 1,
    },
];

export default function FeedbackVisual() {
    const [loading, setLoading] = useState(false)
    const [recommendData, setRecommendData] = useState([])
    const [serviceData, setServiceData] = useState([])
    const [textureData, setTextureData] = useState([])
    const [aromaData, setAromaData] = useState([])
    const [flavorData, setFlavorData] = useState([])
    const [accuracyData, setAccuracyData] = useState([])
    const [valueData, setValueData] = useState([])

    useEffect(() => {
        getData()
    }, [])

    useEffect(() => {
        getGraphRecommendation(recommendData)
        getGraph('chartdiv-service', serviceData)
        getGraph('chartdiv-texture', textureData)
        getGraph('chartdiv-aroma', aromaData)
        getGraph('chartdiv-flavor', flavorData)
        getGraph('chartdiv-accuracy', accuracyData)
        getGraph('chartdiv-value', valueData)
    }, [recommendData, serviceData, textureData, aromaData, flavorData, accuracyData, valueData])

    function getData() {
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/feedback/getRating/`).then(res=>{
            setLoading(false)

            for (let i in res.data) {
                if (i == 'recommendation') {
                    let arr = []
                    let obj = {}
                    for (let x in res.data.recommendation){
                        let likely_count = 0;
                        let unlikely_count = 0
                        for (let y of res.data.recommendation[x]) {
                            if (y.rating == 'likely') {
                                likely_count = y.count;
                            } else {
                                unlikely_count = y.count;
                            }
                        }
                        obj = {
                            category: x,
                            likely: likely_count,
                            unlikely: unlikely_count
                        }
                        arr.push(obj)

                    }
                    // console.log(arr)
                    setRecommendData(arr)
                } else {
                    let arr = []
                    let obj = {}
                    for (let x in res.data.recommendation){

                        let rating_1 = 0;
                        let rating_2 = 0;
                        let rating_3 = 0;
                        let rating_4 = 0;
                        let rating_5 = 0;
                        for (let y of res.data[i][x]) {
                            if (y.rating == 1) {
                                rating_1 = y.count;
                            } else if (y.rating == 2) {
                                rating_2 = y.count;
                            } else if (y.rating == 3) {
                                rating_3 = y.count;
                            } else if (y.rating == 4) {
                                rating_4 = y.count;
                            } else if (y.rating == 5) {
                                rating_5 = y.count;
                            }
                        }
                        obj = {
                            category: x,
                            1: rating_1,
                            2: rating_2,
                            3: rating_3,
                            4: rating_4,
                            5: rating_5,
                        }
                        arr.push(obj)
                    }
                    // console.log(arr)
                    if (i=='service_quality') {
                        setServiceData(arr)
                    } else if (i=='texture') {
                        setTextureData(arr)
                    } else if (i=='aroma') {
                        setAromaData(arr)
                    } else if (i=='flavor') {
                        setFlavorData(arr)
                    } else if (i=='info_accuracy') {
                        setAccuracyData(arr)
                    } else if (i=='value_money') {
                        setValueData(arr)
                    }

                }
            }

        }).catch(res=>{
            setLoading(false)
            console.log(res)
            errorToast('Server Error')
        })
    }

    function  getGraphRecommendation(data) {
        var chart = am4core.create('chartdiv-recommendation', am4charts.XYChart)
        chart.colors.step = 2;
        
        chart.legend = new am4charts.Legend()
        chart.legend.position = 'top'
        chart.legend.paddingBottom = 20
        chart.legend.labels.template.maxWidth = 95
        
        var xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
        xAxis.dataFields.category = 'category'
        xAxis.renderer.cellStartLocation = 0.1
        xAxis.renderer.cellEndLocation = 0.9
        xAxis.renderer.grid.template.location = 0;
        
        var yAxis = chart.yAxes.push(new am4charts.ValueAxis());
        yAxis.min = 0;
        
    
        function createSeries(value, name) {
            var series = chart.series.push(new am4charts.ColumnSeries())
            series.dataFields.valueY = value
            series.dataFields.categoryX = 'category'
            series.name = name
    
            series.events.on("hidden", arrangeColumns);
            series.events.on("shown", arrangeColumns);
    
            var bullet = series.bullets.push(new am4charts.LabelBullet())
            bullet.interactionsEnabled = false
            bullet.dy = 30;
            bullet.label.text = '{valueY}'
            bullet.label.fill = am4core.color('#ffffff')
    
            return series;
        }
    
        chart.data = data
    
    
        createSeries('likely', 'Likely');
        createSeries('unlikely', 'Unlikely');
    
        function arrangeColumns() {
    
            var series = chart.series.getIndex(0);
    
            var w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
            if (series.dataItems.length > 1) {
                var x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
                var x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
                var delta = ((x1 - x0) / chart.series.length) * w;
                if (am4core.isNumber(delta)) {
                    var middle = chart.series.length / 2;
    
                    var newIndex = 0;
                    chart.series.each(function(series) {
                        if (!series.isHidden && !series.isHiding) {
                            series.dummyData = newIndex;
                            newIndex++;
                        }
                        else {
                            series.dummyData = chart.series.indexOf(series);
                        }
                    })
                    var visibleCount = newIndex;
                    var newMiddle = visibleCount / 2;
    
                    chart.series.each(function(series) {
                        var trueIndex = chart.series.indexOf(series);
                        var newIndex = series.dummyData;
    
                        var dx = (newIndex - trueIndex + middle - newMiddle) * delta
    
                        series.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
                        series.bulletsContainer.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
                    })
                }
            }
        }
    }

    function  getGraph(element, data) {
        var chart = am4core.create(element, am4charts.XYChart)
        chart.colors.step = 2;
        
        chart.legend = new am4charts.Legend()
        chart.legend.position = 'top'
        chart.legend.paddingBottom = 20
        chart.legend.labels.template.maxWidth = 95
        
        var xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
        xAxis.dataFields.category = 'category'
        xAxis.renderer.cellStartLocation = 0.1
        xAxis.renderer.cellEndLocation = 0.9
        xAxis.renderer.grid.template.location = 0;
        
        var yAxis = chart.yAxes.push(new am4charts.ValueAxis());
        yAxis.min = 0;
        
    
        function createSeries(value, name) {
            var series = chart.series.push(new am4charts.ColumnSeries())
            series.dataFields.valueY = value
            series.dataFields.categoryX = 'category'
            series.name = name
    
            series.events.on("hidden", arrangeColumns);
            series.events.on("shown", arrangeColumns);
    
            var bullet = series.bullets.push(new am4charts.LabelBullet())
            bullet.interactionsEnabled = false
            bullet.dy = 30;
            bullet.label.text = '{valueY}'
            bullet.label.fill = am4core.color('#ffffff')
    
            return series;
        }
    
        chart.data = data
    
    
        createSeries('1', '1');
        createSeries('2', '2');
        createSeries('3', '3');
        createSeries('4', '4');
        createSeries('5', '5');
    
        function arrangeColumns() {
    
            var series = chart.series.getIndex(0);
    
            var w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
            if (series.dataItems.length > 1) {
                var x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
                var x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
                var delta = ((x1 - x0) / chart.series.length) * w;
                if (am4core.isNumber(delta)) {
                    var middle = chart.series.length / 2;
    
                    var newIndex = 0;
                    chart.series.each(function(series) {
                        if (!series.isHidden && !series.isHiding) {
                            series.dummyData = newIndex;
                            newIndex++;
                        }
                        else {
                            series.dummyData = chart.series.indexOf(series);
                        }
                    })
                    var visibleCount = newIndex;
                    var newMiddle = visibleCount / 2;
    
                    chart.series.each(function(series) {
                        var trueIndex = chart.series.indexOf(series);
                        var newIndex = series.dummyData;
    
                        var dx = (newIndex - trueIndex + middle - newMiddle) * delta
    
                        series.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
                        series.bulletsContainer.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
                    })
                }
            }
        }
    }
    return (
        <div className="content-wrapper">

            <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Feedback Visuals</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item"><a href="/feedback">Feedback</a></li>
                        <li className="breadcrumb-item active">Feedback Visuals</li>
                        </ol>
                    </div>
                    </div>
                </div>


            </div>

            <section className="content">

                <div className="container-fluid">

                <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Recommendation</h4>
                                </div>
                                <div className="card-body">
                                    <div id="chartdiv-recommendation" style={{ width: "100%", height: "500px" }}></div>
                                </div>
                            </div>
                        </div>
                </div>


                <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Service Quality</h4>
                                </div>
                                <div className="card-body">
                                    <div id="chartdiv-service" style={{ width: "100%", height: "500px" }}></div>
                                </div>
                            </div>
                        </div>
                </div>

                <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Texture</h4>
                                </div>
                                <div className="card-body">
                                    <div id="chartdiv-texture" style={{ width: "100%", height: "500px" }}></div>
                                </div>
                            </div>
                        </div>
                </div>

                <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Aroma</h4>
                                </div>
                                <div className="card-body">
                                    <div id="chartdiv-aroma" style={{ width: "100%", height: "500px" }}></div>
                                </div>
                            </div>
                        </div>
                </div>


                <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Flavor</h4>
                                </div>
                                <div className="card-body">
                                    <div id="chartdiv-flavor" style={{ width: "100%", height: "500px" }}></div>
                                </div>
                            </div>
                        </div>
                </div>


                <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Info Accuracy</h4>
                                </div>
                                <div className="card-body">
                                    <div id="chartdiv-accuracy" style={{ width: "100%", height: "500px" }}></div>
                                </div>
                            </div>
                        </div>
                </div>



                <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="card-title">Value Money</h4>
                                </div>
                                <div className="card-body">
                                    <div id="chartdiv-value" style={{ width: "100%", height: "500px" }}></div>
                                </div>
                            </div>
                        </div>
                </div>



                </div>
            </section>
        </div>
    )
}
