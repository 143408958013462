import React, {useEffect,useState} from 'react'
import {useParams} from "react-router-dom";
import axiosInstance from '../../../utils/api';
import ApplicationBarGraph from '../../dashboard/components/ApplicationBarGraph';
import InspectionBarGraph from '../../dashboard/components/InspectionBarGraph';

export default function TreesSummary() {
    const [loading, setLoading] = useState(false)
    let { role,treeId } = useParams();
    const [dashboardData, setDashboardData] = useState({
        application: [],
        breed: [],
        current_soil: [],
        current_weather: {},
        event: {},
        year_soil: {},
        year_weather: {},
    })
    useEffect(() => {
        RenderInspectionGraph()
        RenderApplicationGraph()
    }, [dashboardData])

    useEffect(() => {
        getData()
    }, [])
    
    function RenderInspectionGraph() {
        return (<InspectionBarGraph title="Inspection" data={dashboardData.event}/>)
    }
    function RenderApplicationGraph() {
        return(<ApplicationBarGraph title="Application"  data={dashboardData.application}/>)
    }
    function getData() {
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/operations/summary/tree/${treeId}`).then(res=>{
            setLoading(false)
            setDashboardData(res.data)
        }).catch(res=> {
            setLoading(false)
            console.log(res)
        })
    }
    return (
        <div className="content-wrapper">

            <div class="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i><div class="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Tree Summary</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href={`/${role}`}>Home</a></li>
                        <li className="breadcrumb-item"><a href={`/${role}/trees`}>Tree</a></li>
                        <li className="breadcrumb-item active">Tree Summary</li>
                        </ol>
                    </div>
                    </div>
                </div>


            </div>

            <section className="content">

                <div className="container-fluid">

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="card w-100">
                                <div className="card-header">
                                    <h3 className="card-title">Historical Min Max</h3>
                                </div>
                                <div className="card-body table-responsive p-0">
                                    <table className="table table-hover text-nowrap">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Min</th>
                                                <th>Max</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Temperature(C)</td>
                                                <td>{dashboardData.year_weather.t_min}</td>
                                                <td>{dashboardData.year_weather.t_max}</td>
                                            </tr>
                                            <tr>
                                                <td>Humidity (%)</td>
                                                <td>{dashboardData.year_weather.h_min}</td>
                                                <td>{dashboardData.year_weather.h_max}</td>
                                            </tr>
                                            <tr>
                                                <td>Barometric Pressure (kPa)</td>
                                                <td>{dashboardData.year_weather.bp_min}</td>
                                                <td>{dashboardData.year_weather.bp_max}</td>
                                            </tr>
                                            <tr>
                                                <td>Wind Speed(m/s)</td>
                                                <td>{dashboardData.year_weather.ws_min}</td>
                                                <td>{dashboardData.year_weather.ws_max}</td>
                                            </tr>
                                            <tr>
                                                <td>PAR(μmol/㎡·s)</td>
                                                <td>{dashboardData.year_weather.par_min}</td>
                                                <td>{dashboardData.year_weather.par_max}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="card w-100">
                                <div className="card-header">
                                    <h3 className="card-title">Historical Min Max</h3>
                                </div>
                                <div className="card-body table-responsive p-0">
                                    <table className="table table-hover text-nowrap">
                                        <thead>
                                            <tr>
                                                <th>Parameter</th>
                                                <th>2021</th>
                                                <th>2022</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>EC(mS/cm)</td>
                                                <td>{dashboardData.year_soil.soilec_avg}</td>
                                                <td>-</td>
                                            </tr>
                                            <tr>
                                                <td>pH</td>
                                                <td>{dashboardData.year_soil.soilph_avg}</td>
                                                <td>-</td>
                                            </tr>
                                            <tr>
                                                <td>Moisture(%)</td>
                                                <td>{dashboardData.year_soil.wet_avg}</td>
                                                <td>-</td>
                                            </tr>
                                            <tr>
                                                <td>Temp(C)</td>
                                                <td>{dashboardData.year_soil.soilt_avg}</td>
                                                <td>-</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <RenderInspectionGraph />
                        </div>
                        <div className="col-12 col-md-6">
                            <RenderApplicationGraph />
                        </div>
                    </div>
                </div>
            </section>


            
        </div>
    )
}
