import React, {useState, useEffect} from 'react'

export const NodeAddEditModal = ({data, submit, submitting}) => {
    const [inputs, setInputs] = useState(data)

    useEffect(() => {
        setInputs(data)
    }, [data])

    function handleChange (type, e) {
        setInputs(prev=>({
            ...prev,
            [type]: e.target.value
        }))
    }
    
    return (
        <div >
            <form className="form-horizontal">
                <div className="form-group row">
                    <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Remark</label>
                    <div className="col-sm-10">
                        <input value={inputs.remark ||''} onChange={(e)=>handleChange('remark', e)} type="text" className="form-control"/>
                    </div>
                </div>
                <div className="form-group row">
                    <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Description</label>
                    <div className="col-sm-10">
                        <input value={inputs.description ||''} onChange={(e)=>handleChange('description', e)} type="text" className="form-control"/>
                    </div>
                </div>
            </form>

            <div>
                <button type="button" class="btn btn-primary" onClick={()=>{submit(inputs)}} disabled={submitting}>
                    {submitting ?('Updating...'):('Submit')}
                </button>
            </div>
        </div>
    )
}

export default NodeAddEditModal
