import React, {useEffect, useState} from 'react'
import axiosInstance from '../../utils/api';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { formatDate, formatDateApi } from '../../utils/helpers';
import { errorToast, successToast } from '../../utils/toast'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import styles from "./styles/index.css";

export const MonthlyPage = () => {
	const [loading, setLoading] = useState(false)

    const [graphDefaultData, setGraphDefaultData] = useState([])
    const [graph1Data, setGraph1Data] = useState([])
    const [graph2Data, setGraph2Data] = useState([])
    const [graph3Data, setGraph3Data] = useState([])

    const [graphTaskList, setGraphTaskList] = useState([]);

    const [inputs, setInputs] = useState({
        graph1: '',
        param1: '',
        graph2: '',
        param2: '',
        weather: '',
    });


    useEffect(() => {
        getData()
    }, [])


    function handleInput(e, field) {
        if (field == 'graph1' && e.target.value=='') {
            // Clear param selection if task is unselected
            return setInputs( prev => ({
                ...prev,
                'param1' : '',
                [field] : e.target.value
            }))
        } else if (field == 'graph2' && e.target.value=='') {
            return setInputs( prev => ({
                ...prev,
                'param2' : '',
                [field] : e.target.value
            }))
        }
        setInputs( prev => ({
            ...prev,
            [field] : e.target.value
        }))
    }

    
    function getData() {
        setGraphTaskList([])
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/summary/monthChart/`).then(res=>{
            setLoading(false)
            if (typeof res.data ==  'string') return errorToast(res.data)
            setGraphDefaultData(res.data)
            res.data.soil.map(s => {
                setGraphTaskList(g => [...g, Object.keys(s)[0]])
            })

            arrangeGraph1()
            arrangeGraph2()
            arrangeGraph3()
        }).catch(res=> {
            setLoading(false)
            errorToast('Server Error')
            console.log(res)
        })
    }


    useEffect(() => {
        let root = am5.Root.new("chartdiv");

        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([
            am5themes_Animated.new(root)
        ]);
        
        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        var chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: true,
            panY: true,
            wheelX: "panX",
            wheelY: "zoomX"
        }));

        var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: "none"
        }));
        cursor.lineY.set("visible", false);

        var xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
            baseInterval: {
                timeUnit: "month",
                count: 1
            },
            renderer: am5xy.AxisRendererX.new(root, {}),
            tooltip: am5.Tooltip.new(root, {})
        }));
        let xRenderer = xAxis.get("renderer");
        xRenderer.grid.template.setAll({
            visible:false
        });

        // -------------------------- BAR GRAPH : Series 3 -------------------------- 
        var yAxis3 = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {
                opposite: true
            })
        }));
        let yRenderer3 = yAxis3.get("renderer");
        yRenderer3.labels.template.setAll({
            fill: am5.color("#48F59F"),
        });
        yRenderer3.grid.template.setAll({
            visible:true
        });

        var series3 = chart.series.push(am5xy.ColumnSeries.new(root, {
            name: "Series 3",
            xAxis: xAxis,
            yAxis: yAxis3,
            valueYField: "value",
            valueXField: "date",
            // categoryXField: "date",
            tooltip: am5.Tooltip.new(root, {
              labelText:"{valueY}",
            }),
            fill: am5.color("#48F59F"),
        }));

        series3.columns.template.setAll({
            fillOpacity: 0.5,
            cornerRadiusTL: 5,
            cornerRadiusTR: 5
        });

        // -------------------------- LINE GRAPH 1 : Series 1 -------------------------- 
        var yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {}),
        }));
        let yRenderer = yAxis.get("renderer");
        yRenderer.labels.template.setAll({
            fill: am5.color("#D073E6"),
        });
        yRenderer.grid.template.setAll({
            visible:false
        });

        var series = chart.series.push(am5xy.LineSeries.new(root, {
            name: "Series",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            valueXField: "date",
            tooltip: am5.Tooltip.new(root, {
                labelText: "{valueY}"
            }),
            fill: am5.color("#D073E6"),
            stroke: am5.color("#D073E6"),
        }));

        
        // -------------------------- LINE GRAPH 2 : Series 2 -------------------------- 
        var yAxis2 = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {}),
        }));
        let yRenderer2 = yAxis2.get("renderer");
        yRenderer2.labels.template.setAll({
          fill: am5.color("#72A1FC"),
        });
        yRenderer2.grid.template.setAll({
            visible:false
        });

        var series2 = chart.series.push(am5xy.LineSeries.new(root, {
            name: "Series 2",
            xAxis: xAxis,
            yAxis: yAxis2,
            valueYField: "value",
            valueXField: "date",
            tooltip: am5.Tooltip.new(root, {
                labelText: "{valueY}"
            }),
            fill: am5.color("#72A1FC"),
            stroke: am5.color("#72A1FC"),
        }));

        series.data.processor = am5.DataProcessor.new(root, {
            numericFields: ["value"],
            dateFields: ["date"],
            dateFormat: "yyyy-MM-dd"
        });
        series2.data.processor = am5.DataProcessor.new(root, {
            numericFields: ["value"],
            dateFields: ["date"],
            dateFormat: "yyyy-MM-dd"
        });
        series3.data.processor = am5.DataProcessor.new(root, {
            numericFields: ["value"],
            dateFields: ["date"],
            dateFormat: "yyyy-MM-dd",
        });
        
        chart.set("scrollbarX", am5.Scrollbar.new(root, {
            orientation: "horizontal"
        }));

        series.data.setAll(graph1Data);
        series2.data.setAll(graph2Data);
        series3.data.setAll(graph3Data);

        chart.appear(1000, 100);
        series.appear(1000);
        series2.appear(1000);
        series3.appear(500);
        return () => {
            root.dispose();
        }
    }, [graph1Data, graph2Data, graph3Data])


    function arrangeGraph1() {
        if (inputs.graph1=='' || inputs.param1 =='') return setGraph1Data([])
        let taskSoil = graphDefaultData.soil.filter(s => {
            return s.hasOwnProperty(inputs.graph1)
        })
        if (taskSoil.length > 1 ) return errorToast("Server Error")

        let data = []
        taskSoil[0][inputs.graph1].map(s => {
            data.push({
                date: formatDate(s.date),
                value: s[inputs.param1],
                param: inputs.param1
            })
        })
        setGraph1Data(data)
    }
    function arrangeGraph2() {
        if (inputs.graph2=='' || inputs.param2 =='') return setGraph2Data([])
        let taskSoil = graphDefaultData.soil.filter(s => {
            return s.hasOwnProperty(inputs.graph2)
        })
        if (taskSoil.length > 1 ) return errorToast("Server Error")

        let data = []
        taskSoil[0][inputs.graph2].map(s => {
            data.push({
                date: formatDate(s.date),
                value: s[inputs.param2],
                param: inputs.param2
            })
        })
        setGraph2Data(data)
    }

    
    function arrangeGraph3() {
        if (inputs.weather=='') return setGraph3Data([])

        let data = []
        graphDefaultData.weather.map(w => {
            data.push({
                date: formatDate(w.date),
                value: w[inputs.weather],
                param: inputs.weather
            })
        })
        setGraph3Data(data)
    }


    return (
        <div className="content-wrapper">

        <div class="overlay-wrapper" style={{ display: (loading?'':'none') }}>
            <div class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i><div class="text-bold pt-2"></div></div>
        </div>

        <div className="content-header">
            <div className="container-fluid">
                <div className="row mb-2">
                <div className="col-sm-6">
                    <h1 className="m-0">Monthly Chart</h1>
                </div>
                <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item active">Monthly Chart</li>
                    </ol>
                </div>
                </div>
            </div>
        </div>

        <section className="content">
            <div className="container-fluid">
                
                <div className="row mb-2">
                    <div className="col-6 col-sm-3" style={{color:'#D073E6'}}>
                        Graph 1:
                    </div>
                    <div className="col-6 col-sm-3">
                        <select class="form-control" value={inputs.graph1} onChange={e => handleInput(e,'graph1')}>
                            <option value="">-- SELECT TASK --</option>
                            {graphTaskList.map(g => {
                                return (
                                    <option value={g}>{g}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="col-6 col-sm-3" style={{color:'#D073E6'}}>
                        Parameter 1:
                    </div>
                    <div className="col-6 col-sm-3">
                        <select class="form-control" value={inputs.param1} onChange={e => handleInput(e,'param1')} disabled={inputs.graph1==''?true:false}>
                            <option value="">-- SELECT PARAMETER --</option>
                            <option value="soilec_max">Max EC (mS/cm)</option>
                            <option value="soilec_min">Min EC (mS/cm)</option>
                            <option value="soilph_max">Max pH</option>
                            <option value="soilph_min">Min pH</option>
                            <option value="wet_max">Max Moisture (%)</option>
                            <option value="wet_min">Min Moisture (%)</option>
                            <option value="soilt_max">Max Temp (C)</option>
                            <option value="soilt_min">Min Temp (C)</option>
                        </select>
                    </div>
                </div>

                
                <div className="row mb-2">
                    <div className="col-6 col-sm-3" style={{color:'#72A1FC'}}>
                        Graph 2:
                    </div>
                    <div className="col-6 col-sm-3">
                        <select class="form-control" value={inputs.graph2} onChange={e => handleInput(e,'graph2')}>
                            <option value="">-- SELECT TASK --</option>
                            {graphTaskList.map(g => {
                                return (
                                    <option value={g}>{g}</option>
                                )
                            })}
                        </select>
                    </div>
                    <div className="col-6 col-sm-3" style={{color:'#72A1FC'}}>
                        Parameter 2:
                    </div>
                    <div className="col-6 col-sm-3">
                        <select class="form-control" value={inputs.param2} onChange={e => handleInput(e,'param2')} disabled={inputs.graph2==''?true:false}>
                            <option value="">-- SELECT PARAMETER --</option>
                            <option value="soilec_max">Max EC (mS/cm)</option>
                            <option value="soilec_min">Min EC (mS/cm)</option>
                            <option value="soilph_max">Max pH</option>
                            <option value="soilph_min">Min pH</option>
                            <option value="wet_max">Max Moisture (%)</option>
                            <option value="wet_min">Min Moisture (%)</option>
                            <option value="soilt_max">Max Temp (C)</option>
                            <option value="soilt_min">Min Temp (C)</option>
                        </select>
                    </div>
                </div>

                
                <div className="row mb-2">
                    <div className="col-6 col-sm-3" style={{color:'#48F59F'}}>
                        Weather Parameter:
                    </div>
                    <div className="col-6 col-sm-3 mb-2">
                        <select class="form-control" value={inputs.weather} onChange={e => handleInput(e,'weather')}>
                            <option value="">-- SELECT PARAMETER --</option>
                            <option value="par_max">Max PAR (μmol/㎡·s)</option>
                            <option value="ws_max">Max Wind Speed (m/s)</option>
                            <option value="ws_min">Max Wind Speed (m/s)</option>
                            <option value="h_max">Max Humidity (%)</option>
                            <option value="h_min">Min Humidity (%)</option>
                            <option value="t_max">Max Temp (C)</option>
                            <option value="t_min">Min Temp (C)</option>
                            <option value="rainfall">Rainfall (mm)</option>
                        </select>
                    </div>
                    <div className="col-6 col-sm-3"></div>
                    <div className="col-6 col-sm-3">
                        <button type="button" class="btn btn-block btn-primary" onClick={()=>getData()}>Generate</button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div id="chartdiv" style={{ width: "100%", minHeight: "400px" }}></div>
                    </div>
                </div>
            </div>
        </section>

        </div>
    );
};
