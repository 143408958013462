import React, {useEffect,useState} from 'react'
import { useParams } from 'react-router-dom'

export default function Operations(props) {
    let { role } = useParams();
    const {path, match} = props;
    const [loading, setLoading] = useState(false)

    return (
        <div className="content-wrapper">

            <div class="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i><div class="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Operations</h1>
                    </div>
                    <div className="col-sm-6">
                        {/* <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item active">Operations</li>
                        </ol> */}
                    </div>
                    </div>
                </div>
            </div>

            <section className="content">

                <div className="container-fluid">

                    

                    <div className="row">
                        <div className="col-12">
                            <div class="text-center p-3">
                                {/* <h3>Morning</h3>
                                <h3>Cloudatik</h3> */}

                                
                                <div class="row my-2 px-4 justify-content-center">
                                    <a class="btn btn-primary w-100 col-12 col-md-3" href={`/${role}/operations/scan-qr`}>Scan QR</a>
                                </div>
                                <div class="row my-2 px-4 justify-content-center">
                                    <a class="btn btn-info w-100 col-12 col-md-3" href={`/${role}/operations/select-trees`}>Select Specific Tree No.</a>
                                </div>
                                <div class="row my-2 px-4 justify-content-center">
                                    <a class="btn btn-success w-100 col-12 col-md-3" href={`/${role}/operations/select-farms`}>Application by Task/Zone Select</a>
                                </div>
                                {/* <div class="row mt-5 px-4">
                                    <button class="btn btn-warning w-100">Logout</button>
                                </div> */}
                                
                            </div>
                        </div>
                    </div>
                
                
                
                </div>
            </section>


            
        </div>
    )
}
