import React, {useState, useEffect} from 'react'
import { useParams } from 'react-router-dom'
import QrReader from 'react-qr-scanner'
import axiosInstance from '../../utils/api';
import './styles/scan-qr.css';
import { Html5QrcodeScanner, Html5QrcodeSupportedFormats } from "html5-qrcode";

export default function ScanQr() {
    let { role } = useParams();
    const [loading, setLoading] = useState(false)
    let html5QrcodeScanner;
    
    useEffect(() => {
        html5QrcodeScanner = new Html5QrcodeScanner("html5qr-code-full-region", {
            fps: 10,
            qrbox: 250,
            disableFlip: false,
            formatsToSupport: [ Html5QrcodeSupportedFormats.QR_CODE ]
        });
        html5QrcodeScanner.render(
            onNewScanResult, 
        );
    }, [])
    
    function onNewScanResult(decodedText, decodedResult) {
        console.log("App [result]", decodedText);
        let pattern = "farm.matang.app"
        if (decodedText.match(pattern)) {
            setLoading(true)
            html5QrcodeScanner.clear();
            let get_tree_id = decodedText.split("/")[4]
            axiosInstance.get(`${process.env.REACT_APP_API}/trees/show/${get_tree_id}`).then(res=>{
                setLoading(false)
                return window.location.href = `/${role}/operations/tree/${get_tree_id}/activity/`
            }).catch(res => {
                setLoading(false)
                alert('Invalid QR')
            })
        }
    }

    return (
        <div className="content-wrapper">

            <div class="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i><div class="text-bold pt-2"></div></div>
            </div>

            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Scan QR</h1>
                    </div>
                    <div className="col-sm-6">
                        {/* <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item"><a href={`/${role}/operations/scan-qr`}>Scan QR</a></li>
                        <li className="breadcrumb-item"><a href={`/${role}/operations/tree/${treeId}/activity`}>Activity</a></li>
                        <li className="breadcrumb-item active">Inspection</li>
                        </ol> */}
                    </div>
                    </div>
                </div>
            </div>

            <section className="content">

                <div className="container-fluid">

                    

                    <div className="row">
                        <div className="col-12">
                            <div class="text-center p-3">

                                <div className="row justify-content-center">
                                    <div id="html5qr-code-full-region" style={{width:'100%', height: '100%'}}/>
                                </div>


                                {/* <div class="row my-2 px-4">
                                <a class="btn btn-primary w-100" href={`/${role}/operations/tree/0131a2912a3443b3a3f5ec17e7f95a51/activity`}>Scan QR</a>
                                </div> */}
                                
                                <div class="row my-2 px-4">
                                    <a class="btn btn-warning w-100" href={`/${role}/operations`}>
                                    Back
                                    </a>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                
                
                
                </div>
            </section>


            
        </div>
    )
}
