import React, {useEffect,useState} from 'react'

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { formatDateTimeDisplay } from '../../../utils/helpers';
let default_data=[
    {
        "datetime": "2020-12-10T02:45:43Z",
        "description": "diameter",
        "value": "0.7"
    },
    {
        "datetime": "2020-12-10T02:49:13Z",
        "description": "height",
        "value": "1.2"
    },
]
export default function GrowthGraph({ title, data}) {
    const [graphDiameterData, setGraphDiameterData] = useState([])
    const [graphHeightData, setGraphHeightData] = useState([])
    useEffect(() => {
        if (data) {
            arrangeData()
        }
    }, [])
    
    function arrangeData() {
        let arr_diameter = []
        let arr_height = []
        for (let i in data) {
            data[i]['date']=formatDateTimeDisplay(data[i]['datetime'])
            data[i]['value']=parseFloat(data[i]['value'])

            if (data[i]['description']=='diameter') {
                arr_diameter.push(data[i])
            } else if (data[i]['description']=='height') {
                arr_height.push(data[i])
            }
        }
        setGraphDiameterData(arr_diameter)
        setGraphHeightData(arr_height)
    }
    
    useEffect(() => {
        let root = am5.Root.new("chartdiv-growth");

        // Set themes
        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([
            am5themes_Animated.new(root)
        ]);
        
        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        var chart = root.container.children.push(
            am5xy.XYChart.new(root, {
                panX: true,
                panY: true,
                wheelX: "panX",
                wheelY: "zoomX",
                layout: root.verticalLayout,
                paddingLeft:0,
                paddingRight:0,
            })
        );
        
        // Add cursor
        // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
        var cursor = chart.set(
            "cursor",
            am5xy.XYCursor.new(root, {
            behavior: "none",
            })
        );
        cursor.lineY.set("visible", false);
        
        var colorSet = am5.ColorSet.new(root, {});
        
        
        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        var xRenderer = am5xy.AxisRendererX.new(root, {});
        xRenderer.grid.template.set("location", 0.5);
        xRenderer.labels.template.setAll({ location: 0.5, multiLocation: 0.5, fontSize: 11 });
        
        var xAxis = chart.xAxes.push(
            am5xy.DateAxis.new(root, {
                baseInterval: { timeUnit: "day", count:2 },
                renderer: xRenderer,
                tooltip: am5.Tooltip.new(root, {}),
            })
        );
        
        var yRenderer = am5xy.AxisRendererY.new(root, {});
        yRenderer.labels.template.setAll({
            fill: am5.color('#3272FA'),
            fontSize: 11
        });
        
        var yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: yRenderer
            })
        );
        yAxis.children.unshift(
            am5.Label.new(root, {
                rotation: -90,
                text: "[#3272FA]Diameter[/]",
                y: am5.p50,
                centerX: am5.p50,
                fontSize:11,
            })
        );
        
        // Diamater
        var series = chart.series.push(
            am5xy.LineSeries.new(root, {
            name:"Diameter (cm)",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            valueXField: "date",
            tooltip: am5.Tooltip.new(root, {
                labelText: "[bold]{name}[/]\nDate:[bold]{valueX}[/]\nValue:[bold]{valueY}[/]"
            })
            })
        );
        
        // Set up data processor to parse string dates
        // https://www.amcharts.com/docs/v5/concepts/data/#Pre_processing_data
        series.data.processor = am5.DataProcessor.new(root, {
            dateFormat: "yyyy-MM-dd HH:mm",
            dateFields: ["date"]
        });
        
        series.strokes.template.setAll({ strokeDasharray: [3, 3], strokeWidth: 2, fill:am5.color(0x990000) });
        
        series.bullets.push(function() {
            return am5.Bullet.new(root, {
                sprite: am5.Circle.new(root, {
                    radius: 5,
                    fill: am5.color('#3272FA')
                })
            })
        })

          
        var yRenderer2 = am5xy.AxisRendererY.new(root, {
            opposite:true,
        });
        yRenderer2.grid.template.set("forceHidden", true);
        yRenderer2.labels.template.setAll({
            fill: am5.color('#FF0000'),
            fontSize: 11
        });
        
        var yAxis = chart.yAxes.push(
            am5xy.ValueAxis.new(root, {
                renderer: yRenderer2,
            })
        );
        yAxis.children.unshift(
            am5.Label.new(root, {
                rotation: 90,
                text: "[#FF0000]Height[/]",
                y: am5.p50,
                x: 90,
                centerX: am5.p50,
                fontSize:11,
            })
        );

        // Height
        var series2 = chart.series.push(
            am5xy.LineSeries.new(root, {
            name:"Height (cm)",
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            valueXField: "date",
            stroke: am5.color(0x990000),
            tooltip: am5.Tooltip.new(root, {
                labelText: "[bold]{name}[/]\nDate:[bold]{valueX}[/]\nValue:[bold]{valueY}[/]"
            })
            })
        );
        
        // Set up data processor to parse string dates
        // https://www.amcharts.com/docs/v5/concepts/data/#Pre_processing_data
        series2.data.processor = am5.DataProcessor.new(root, {
            dateFormat: "yyyy-MM-dd HH:mm",
            dateFields: ["date"]
        });
        
        series2.strokes.template.setAll({ strokeDasharray: [3, 3], strokeWidth: 2,  });
        
        series2.bullets.push(function() {
            return am5.Bullet.new(root, {
                sprite: am5.Circle.new(root, {
                    radius: 5,
                    fill: am5.color(0x990000)
                })
            })
        })
        
        root.dateFormatter.setAll({
            dateFormat: "yyyy-MM-dd",
            dateFields: ["valueX"]
        });
        
        series.data.setAll(graphDiameterData);
        series2.data.setAll(graphHeightData);
        series.appear(1000);
        
        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        chart.appear(1000, 100);
        


    return () => {
        root.dispose();
    }
    }, [graphDiameterData,graphHeightData])


    return (
        <div className="card">
            <div className="card-header">
                <h4 className="card-title">{title}</h4>
            </div>
            <div className="card-body">
                <div id="chartdiv-growth" style={{ width: "100%", minHeight: "300px" }}></div>
            </div>
        </div>
    )
}
