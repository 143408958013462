
import axios from 'axios'

const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API,
    timeout: 240000,
    headers: {
        'Authorization': localStorage.getItem('token:access') ? "JWT " + localStorage.getItem('token:access') : null,
        'Content-Type': 'application/json',
        'accept': 'application/json'
    }
});

axiosInstance.interceptors.response.use(
   response => response,
   error => {
       const originalRequest = error.config;

       if (error.response.data.code === "token_not_valid" && error.response.status === 401) {
               const refreshToken = localStorage.getItem('token:refresh');

               if (refreshToken){
                   const tokenParts = JSON.parse(atob(refreshToken.split('.')[1]));

                   // exp date in token is expressed in seconds, while now() returns milliseconds:
                   const now = Math.ceil(Date.now() / 1000);

                   if (tokenParts.exp > now) {
                       return axiosInstance.post(`${process.env.REACT_APP_API}/token/refresh/`, {refresh: refreshToken}).then((response) => {
           
                           localStorage.setItem('token:access', response.data.access);
                        //    localStorage.setItem('token:refresh', response.data.refresh);
           
                           axiosInstance.defaults.headers['Authorization'] = "JWT " + response.data.access;
                           originalRequest.headers['Authorization'] = "JWT " + response.data.access;
           
                           return axiosInstance(originalRequest);
                       }).catch(err => {
                           console.log(err)
                       });
                   }else{
                       console.log("Refresh token is expired", tokenParts.exp, now);
                       localStorage.clear()
                       window.location.href = '/login/';
                       return Promise.reject(error);
                   }
               }else{
                   console.log("Refresh token not available.")
                   localStorage.clear()
                   window.location.href = '/login/';
                   return Promise.reject(error);
               }
       }
     
    
     // specific error handling done elsewhere
     return Promise.reject(error);
 }
);

export default axiosInstance;