import React, {useEffect, useState} from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import TopNav from '../components/TopNav';
import SideNav from '../components/SideNav';
import Footer from '../components/Footer';
import Dashboard from '../pages/dashboard/Dashboard';
import Gallery from '../pages/media-gallery/Gallery';

import GalleryDetail from '../pages/media-gallery/pages/GalleryDetail';
import GalleryAddEdit from '../pages/media-gallery/pages/GalleryAddEdit';
import Error404 from '../pages/errors/Error404';
import Library from '../pages/library/Library';
import LibraryAddEdit from '../pages/library/pages/LibraryAddEdit';
import Farm from '../pages/farm/Farm';
import FarmAddEdit from '../pages/farm/pages/FarmAddEdit';
import TrackNTrace from '../pages/trackntrace/TrackNTrace';
import Tag from '../pages/tag/Tag';
import TagAddEdit from '../pages/tag/pages/TagAddEdit';
import BatchInput from '../pages/trackntrace/pages/BatchInput';
import BatchInputSerial from '../pages/trackntrace/pages/BatchInputSerial';
import BatchInputSerialAddEdit from '../pages/trackntrace/pages/BatchInputSerialAddEdit';
import { ProtectedRoute } from '../routes/ProtectedRoute';
import Feedback from '../pages/feedback/Feedback';
import FeedbackRecord from '../pages/feedback/pages/FeedbackRecord';
import FeedbackDetail from '../pages/feedback/pages/FeedbackDetail';
import FeedbackVisual from '../pages/feedback/pages/FeedbackVisual';
import Trees from '../pages/trees/Trees';
import Users from '../pages/users/Users';
import Operations from '../pages/operations/Operations';
import UserAddEdit from '../pages/users/pages/UserAddEdit';
import TreesAddEdit from '../pages/trees/pages/TreesAddEdit';
import FarmTree from '../pages/farm/pages/FarmTree';
import TreesView from '../pages/trees/pages/TreesView';
import ScanQr from '../pages/operations/ScanQr';
import Step1 from '../pages/operations/Activity';
import Inspection from '../pages/operations/Inspection';
import Activity from '../pages/operations/Activity';
import Form from '../pages/operations/Form';
import Application from '../pages/operations/Application';
import SelectTrees from '../pages/operations/SelectTrees';
import SelectFarms from '../pages/operations/SelectFarms';
import FarmSummary from '../pages/farm/pages/FarmSummary';
import TreesSummary from '../pages/trees/pages/TreesSummary';
import {DailyPage} from '../pages/charts/DailyPage';
import {MonthlyPage} from '../pages/charts/MonthlyPage';
import Input from '../pages/input/Input';
import Showcase from '../pages/showcase/Showcase';

const Admin = (props) => {
    const {path, match} = props;
	const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('token:access'))
	const [role, setRole] = useState('worker')

	useEffect(() => {
        if (localStorage.getItem('token:access')) {
            setIsAuthenticated(true)
            if (localStorage.getItem('user:data') && JSON.parse(localStorage.getItem('user:data'))) {
                let user_role = JSON.parse(localStorage.getItem('user:data')).role
                if (user_role == 'admin') {
                    setRole('admin')
                } else {
                    setRole('worker')
                }
            }
        } else {
            setIsAuthenticated(false)
        }
        console.log('Admin.js')
	}, [])

    
    if (role!='admin' || !isAuthenticated) {
        return <Error404 />
    }

    return (
        <React.Fragment>
				<div className="App hold-transition sidebar-mini layout-fixed">
					<div className="wrapper">
						<TopNav/>
						<SideNav role={role}/>
						<ProtectedRoute {...props}  path={`/:role/`} component={Dashboard} />


						{/* FARM  */}
						<ProtectedRoute {...props} path={`/:role/farms/`} component={Farm} />
						<ProtectedRoute {...props}  path={`/:role/farms/create`} component={FarmAddEdit} />
						<ProtectedRoute {...props}  path={`/:role/farms/:farmId/edit`} component={FarmAddEdit} />
						<ProtectedRoute {...props}  path={`/:role/farms/:farmId/summary`} component={FarmSummary} />

						
						<ProtectedRoute {...props}  path={`/:role/farm-tree/:farmId`} component={FarmTree} />
						
						{/* TREES  */}
						<ProtectedRoute {...props}  path={`/:role/trees/`} component={Trees} />
						<ProtectedRoute {...props}  path={`/:role/trees/create`} component={TreesAddEdit} />
						<ProtectedRoute {...props}  path={`/:role/trees/:treeId/edit`} component={TreesAddEdit} />
						<ProtectedRoute {...props}  path={`/:role/trees/:treeId/view`} component={TreesView} />
						<ProtectedRoute {...props}  path={`/:role/trees/:treeId/summary`} component={TreesSummary} />

						{/* USERS  */}
						<ProtectedRoute {...props}  path={`/:role/users/`} component={Users} />
						<ProtectedRoute {...props}  path={`/:role/users/create`} component={UserAddEdit} />
						<ProtectedRoute {...props}  path={`/:role/users/:username/edit`} component={UserAddEdit} />

						{/* OPERATIONS  */}
						<ProtectedRoute {...props} path={`/:role/operations`} component={Operations} />

						{/* INPUT  */}
						<ProtectedRoute {...props} path={`/:role/input`} component={Input} />

						{/* SHOWCASE  */}
						<ProtectedRoute {...props} path={`/:role/showcase`} component={Showcase} />
						
						{/* CHARTS  */}
						<ProtectedRoute {...props} path={`/:role/daily-charts`} component={DailyPage} />
						<ProtectedRoute {...props} path={`/:role/monthly-charts`} component={MonthlyPage} />
						
						{/* SCAN QR  */}
						<ProtectedRoute {...props} exact path={`/:role/operations/scan-qr/`} component={ScanQr} />
						<ProtectedRoute {...props} exact path={`/:role/operations/:treeFarm(tree|farm)/:treeId/activity/`} component={Activity} />
						<ProtectedRoute {...props} exact path={`/:role/operations/:treeFarm(tree|farm)/:treeId/inspection/`} component={Inspection} />
						<ProtectedRoute {...props} exact path={`/:role/operations/:treeFarm(tree|farm)/:treeId/application/`} component={Application} />
						<ProtectedRoute {...props} exact path={`/:role/operations/:treeFarm(tree|farm)/:id/:inspectionApplication(inspection|application)/:type`} component={Form} />

						
						<ProtectedRoute {...props}  path={`/:role/operations/select-trees/`} component={SelectTrees} />
						<ProtectedRoute {...props}  path={`/:role/operations/select-farms/`} component={SelectFarms} />



						{/* GALLERY  */}
						{/* <ProtectedRoute  exact path="/gallery" component={Gallery} />
						<ProtectedRoute  exact path={`/gallery/create`} component={GalleryAddEdit} />
						<ProtectedRoute  exact path={`/gallery/:folderId/edit`} component={GalleryAddEdit} />
						<ProtectedRoute exact path={`/gallery/:folderId`} component={GalleryDetail} /> */}

						{/* LIBRARY  */}
						{/* <ProtectedRoute exact path={`/library/`} component={Library} />
						<ProtectedRoute exact path={`/library/create`} component={LibraryAddEdit} />
						<ProtectedRoute exact path={`/library/:libraryId/edit`} component={LibraryAddEdit} /> */}

						{/* TRACK & TRACE  */}
						{/* <ProtectedRoute exact path={`/trackntrace/`} component={TrackNTrace} />
						<ProtectedRoute exact path={`/trackntrace/batch-input`} component={BatchInput} />
						<ProtectedRoute exact path={`/trackntrace/batch-input/serials/:batch/:serialFrom/:serialTo`} component={BatchInputSerial} />
						<ProtectedRoute exact path={`/trackntrace/batch-input/serials/:batch/:serialFrom/:serialTo/edit/:tagId`} component={BatchInputSerialAddEdit} /> */}


						{/* TAG  */}
						{/* <ProtectedRoute exact path={`/tag/`} component={Tag} />
						<ProtectedRoute exact path={`/tag/:tagId/:operation(edit|view)`} component={TagAddEdit} /> */}

						{/* FEEDBACK  */}
						{/* <ProtectedRoute exact path={`/feedback/`} component={Feedback} />
						<ProtectedRoute exact path={`/feedback-records/`} component={FeedbackRecord} />
						<ProtectedRoute exact path={`/feedback-records/:feedbackId/view`} component={FeedbackDetail} />
						<ProtectedRoute exact path={`/feedback-visuals/`} component={FeedbackVisual} /> */}

						{/* <Route path="*" >
							<Error404/>
						</Route> */}
						<Footer/>
					</div>
				</div>
  </React.Fragment>
    )
}

export default Admin