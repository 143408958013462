import React, {useEffect,useState} from 'react'
import { successToast, errorToast } from '../../../utils/toast';
import  axiosInstance  from '../../../utils/api'

export default function ChangePasswordModal({changePasswordModal, setChangePasswordModal, setLoading, username, getUserData}) {

    const [password, setPassword] = useState({
        old_password: '',
        new_password: ''
    })
    function handlePasswordInput(e, field) {
        setPassword({
            ...password,
            [field]: e.target.value
        })
    }
    
    function submitChangePassword() {
        setLoading(true)
        axiosInstance.post(`${process.env.REACT_APP_API}/users/update/password/${username}`, password).then(res=>{
            setLoading(false)
            successToast('Succeesfully changed')
            getUserData(username)
        }).catch(res=>{
            setLoading(false)
            errorToast(res.response.data)
        })
    }


    return (
        <div class={changePasswordModal ? "modal fade show" : "modal fade"} id="modal-xl" style={changePasswordModal ? {display:'block'} : {display:'none'}}>
            <div class="modal-dialog modal-xl modal-dialog-scrollable " role="document">
            <div class="modal-content">
                <div class="modal-header">
                <h4 class="modal-title">Change password</h4>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={()=>{setChangePasswordModal(false)}}>
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                    <form className="form-horizontal">
                        <div className="card-body">
                            <div className="form-group row">
                                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Old Password</label>
                                <div className="col-sm-10">
                                    <input value={password.old_password} onChange={(e)=>handlePasswordInput(e,'old_password')} type="text" className="form-control" placeholder="Required"/>
                                </div>
                            </div>
                            <div className="form-group row">
                                <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">New Password</label>
                                <div className="col-sm-10">
                                    <input value={password.new_password} onChange={(e)=>handlePasswordInput(e,'new_password')} type="text" className="form-control" placeholder="Required"/>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default" onClick={()=>{setChangePasswordModal(false)}}>Close</button>
                    <button type="button" class="btn btn-primary" onClick={()=>{submitChangePassword()}}>Submit</button>
                </div>
            </div>
            </div>
        </div>
    )
}
