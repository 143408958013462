import React, {useEffect,useState,useMemo} from 'react'
import axiosInstance from '../../utils/api'
import { errorToast, successToast } from '../../utils/toast'
import {Link, useParams} from "react-router-dom";
import DataTable from 'react-data-table-component';

var temp_full_trees_holder = []
export default function Trees() {
    const [loading, setLoading] = useState(false)
    const [trees, setTress] = useState([])
    const [serialStatus, setSerialStatus] = useState(true)
    let { role } = useParams();

    const columns = [
        {
            name: 'Serial',
            selector: row => row.treeid,
            sortable: false,
            cell: (row, index, column, id) => {
                return <Link to={`/${role}/trees/${row.treeid}/view`}>{row.serial ? row.serial : '-'}</Link>
            },
        },
        {
            name: 'Ref',
            selector: row => row.ref ?? "-",
            sortable: true,
        },
        {
            name: 'Breed',
            selector: row => row.breed,
            sortable: true,
        },
        {
            name: 'Year',
            selector: row => row.year,
            sortable: true,
        },
        {
            name: 'Height (ft)',
            selector: row => row.height,
            sortable: true,
        },
        {
            name: 'Diameter (mm)',
            selector: row => row.diameter,
            sortable: true,
        },
        {
            name: 'Categories',
            selector: row => row.farm,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: (row, index, column, id) => {
                return (
                    <a href={`/${role}/trees/${row.treeid}/edit`} class="btn btn-tool">
                        <i class="fas fa-edit"></i>
                    </a>
                )
            },
        },
    ];

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/trees`).then(res=> {
            setLoading(false)
            console.log(res.data)
            
            temp_full_trees_holder = res.data
            // sort if no serial, push to back
            let serial_tree = []
            let non_serial_tree = []
            for (let x in res.data) {
                if (!res.data[x].serial) {
                    non_serial_tree.push(res.data[x])
                } else {
                    serial_tree.push(res.data[x])
                }
            }

            setTress([ ...serial_tree, ...non_serial_tree])
        }).catch(res=>{
            setLoading(false)
            console.log(res)
            errorToast('Server Error')
        })
    }

    const [filterSerial, setFilterSerial] = useState('');
    const [filterBreed, setFilterBreed] = useState('');
    const [filterYear, setFilterYear] = useState('');
    const [filterCategory, setFilterCategory] = useState('');
    let filteredItems = trees.filter(
		item => {
            let year = ""
            if (item.year) {
                year = item.year
            }

            let breed = ""
            if (item.breed) {
                breed = item.breed
            }

            let serial = ""
            if (item.serial) {
                serial = item.serial
            }

            let farm = ""
            if (item.farm) {
                farm = item.farm
            }

            if ((breed.toLowerCase().includes(filterBreed.toLowerCase())) && 
                (serial.toLowerCase().includes(filterSerial.toLowerCase())) && 
                (year.toLowerCase().includes(filterYear.toLowerCase())) && 
                (farm.toLowerCase().includes(filterCategory.toLowerCase()))
            ) {
                return item
            }
        }
	)

    function handleHideSerial() {
        let serial_tree = []
        let non_serial_tree = []
        console.log(temp_full_trees_holder)

        for (let x in temp_full_trees_holder) {
            if (!temp_full_trees_holder[x].serial) {
                non_serial_tree.push(temp_full_trees_holder[x])
            } else {
                serial_tree.push(temp_full_trees_holder[x])
            }
        }
        
        if (serialStatus) {
            // show ONLY trees with serial
            setSerialStatus(false)
            setTress([ ...serial_tree])
        } else {
            // show all
            setSerialStatus(true)
            setTress([ ...serial_tree, ...non_serial_tree])
        }

    }


    const subHeaderComponentMemo = useMemo(() => {
		return (
            <>
            <input value={filterSerial} onChange={e=>{setFilterSerial(e.target.value)}} placeholder="Search Serial" className='mr-2'/>
            <input value={filterBreed} onChange={e=>{setFilterBreed(e.target.value)}} placeholder="Search Breed" className='mr-2'/>
            <input value={filterYear} onChange={e=>{setFilterYear(e.target.value)}} placeholder="Search Year" className='mr-2'/>
            <input value={filterCategory} onChange={e=>{setFilterCategory(e.target.value)}} placeholder="Search Category"/>
            </>
		);
	}, [filterSerial, filterBreed, filterYear, filterCategory]);

    return (
        <div className="content-wrapper">

            <div class="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i><div class="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Trees</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href={`${role}`}>Home</a></li>
                        <li className="breadcrumb-item active">Trees</li>
                        </ol>
                    </div>
                    </div>
                </div>


            </div>

            <section className="content">

                <div className="container-fluid">


                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title"></h3>

                                    {/* <div class="card-tools">
                                        <button type="button" class="btn btn-block btn-default"  onClick={()=>{ return window.location = '/'+role+"/trees/create" }}>Create Trees</button>
                                    </div> */}
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-block btn-default"  onClick={()=>{ handleHideSerial() }}>{serialStatus ? "Hide" : "Show"} No Serial</button>
                                    </div>
                                </div>
                                <div className="card-body table-responsive p-0">
                                    <DataTable
                                        columns={columns}
                                        data={filteredItems}
                                        pagination
                                        paginationComponentOptions={{
                                            noRowsPerPage:false,
                                            // selectAllRowsItemText: "ALL",
                                            // selectAllRowsItem: true,
                                        }}
                                        paginationPerPage={50}
                                        paginationRowsPerPageOptions={[10, 25, 50, 100]}
                                        subHeader
                                        subHeaderComponent={subHeaderComponentMemo}
                                    />
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                
                
                </div>
            </section>


            
        </div>
    )
}

{/* <table className="table table-hover text-nowrap">
    <thead>
        <tr>
            <th>Serial</th>
            <th>Breed</th>
            <th>Year</th>
            <th>Height (ft)</th>
            <th>Diameter (mm)</th>
            <th>Categories</th>
            <th>Actions</th>
        </tr>
    </thead>
    <tbody>
        {trees.length > 0 ? 
        trees.map( tree => (
            <tr>
                <td>
                    <a href={`/${role}/trees/${tree.treeid}/view`}>{tree.serial}</a>
                </td>
                <td>{tree.breed ? tree.breed : '-'}</td>
                <td>{tree.year}</td>
                <td>{tree.height}</td>
                <td>{tree.diameter}</td>
                <td>{tree.farm}</td>
                <td>
                    <a href={`/${role}/trees/${tree.treeid}/edit`} class="btn btn-tool">
                        <i class="fas fa-edit"></i>
                    </a>
                </td>
            </tr>
        ))
        : (
        <tr>
            <td>No Data</td>
        </tr>
        )}
    </tbody>
</table> */}