import React from 'react'

export const ViewImageModal = ({viewImageModal,setViewImageModal, image=""}) => {
    return (
        <div class={viewImageModal ? "modal fade show" : "modal fade"} id="modal-xl" style={viewImageModal ? {display:'block', textAlign:'center'} : {display:'none'}}>
            <div class="modal-dialog modal-xl modal-dialog-scrollable " role="document">
            <div class="modal-content">
                <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={()=>{setViewImageModal(false)}}>
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                    <div>
                        <img alt="matang" class="img-responsive" style={{ width:'100%', height: 'auto', maxWidth:'400px' }} src={image}/>
                    </div>
                </div>
                <div class="modal-footer justify-content-between">
                    <button type="button" class="btn btn-default" onClick={()=>{setViewImageModal(false)}}>Close</button>
                </div>
            </div>
            </div>
        </div>
    )
}
