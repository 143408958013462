import React, {useEffect,useState} from 'react'
import axios from 'axios';
import {config} from '../../utils/config';
import { errorToast, successToast } from '../../utils/toast';
import  axiosInstance  from '../../utils/api'
import {useParams} from "react-router-dom";

export default function Farm() {
    const [farms, setFarms] = useState([])
    const [loading, setLoading] = useState(false)
    let { role } = useParams();

    useEffect(() => {
        getList()
    }, [])

    function getList() {
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/farms/`).then(res=>{
            setLoading(false)
            setFarms(res.data)
        }).catch(res=>{
            setLoading(false)
            console.log(res)
            errorToast('Server Error')
        })
        
    }

    function deleteFarm(val) {
        setLoading(true)
        axiosInstance.delete(`${process.env.REACT_APP_API}/farms/delete/${val.farmid}`, {
            headers: config.headers,
        }).then(res=>{
            setLoading(false)
            successToast(res.data.message)
            getList()
        }).catch(res=>{
            setLoading(false)
            errorToast('Server Error')
            return window.location.reload()
        })
    }

    return (
        <div className="content-wrapper">

            <div class="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i><div class="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Farm Menu</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href={`/${role}`}>Home</a></li>
                        <li className="breadcrumb-item active">Farm Menu</li>
                        </ol>
                    </div>
                    </div>
                </div>


            </div>

            <section className="content">

                <div className="container-fluid">


                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title"></h3>

                                    <div class="card-tools">
                                        <button type="button" class="btn btn-block btn-default"  onClick={()=>{ return window.location = "/"+role+"/farms/create" }}>Create Farm</button>
                                    </div>
                                </div>
                                <div className="card-body table-responsive p-0">
                                    <table className="table table-hover text-nowrap">
                                        <thead>
                                            <tr>
                                                <th>No.</th>
                                                <th>Name</th>
                                                <th>Latitude</th>
                                                <th>Logitude</th>
                                                <th>Address</th>
                                                <th>Size (Acre)</th>
                                                <th>No. of Trees</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {farms.length > 0 ? 
                                            farms.map( (farm, key) => (
                                                <tr>
                                                    <td>{key + 1}</td>
                                                    <td><a href={`/${role}/farms/${farm.farmid}/summary`}>{farm.name}</a></td>
                                                    <td>{farm.latitude}</td>
                                                    <td>{farm.longitude}</td>
                                                    <td>{farm.address}</td>
                                                    <td>{farm.size}</td>
                                                    <td>{farm.no_tree}</td>
                                                    <td>
                                                        <a href={`/${role}/farms/${farm.farmid}/edit`} class="btn btn-tool">
                                                            <i class="fas fa-edit"></i>
                                                        </a>
                                                        <a href="javascript:void(0)" class="btn btn-tool" onClick={()=>{
                                                            if (window.confirm('Are you sure you want to delete farm?')) {
                                                                deleteFarm(farm)
                                                            }
                                                        }}>
                                                            <i class="fas fa-trash"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            ))
                                            : (
                                            <tr>
                                                <td>No Data</td>
                                            </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>

                                {/* <div class="card-footer clearfix">
                                    <ul class="pagination pagination-sm m-0 float-right">
                                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                    </div>
                
                
                
                
                </div>
            </section>


            
        </div>
    )
}
