import React, {useEffect,useState} from 'react'
import axiosInstance from '../../../utils/api'
import { formatDateTimeDisplay } from '../../../utils/helpers'
import { errorToast, successToast } from '../../../utils/toast'

export const NodeViewModal = ({data}) => {
    const [images, setImages] = useState([])
    const [loading, setLoading] = useState(false)
    useEffect(() => {
        setImages([])
       if (data.id) {
        getImages(data.id)
       }
    }, [])

    function getImages(id) {
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/operations/show/${id}`).then(res => {
            setLoading(false)
            setImages(res.data.image)
        }).catch(res=>{
            setLoading(false)
            console.log(res)
        })
    }

    
    function uploadImage(e) {
        if (e.target.files.length < 1) {
            return null
        }
        // console.log(e.target.files)
        let bodyFormData = new FormData();
        let counter = 1;
        for (let i in e.target.files){
            if (Number.isInteger(parseInt(i))) {
                let file = new File([e.target.files[i]], new Date().toISOString() + "_" + counter, {type: "image/jpg", lastModified: Date.now()});
                bodyFormData.append(counter, file);
            }
            counter ++
        }

        setLoading(true)
        axiosInstance.post(`${process.env.REACT_APP_API}/operations/update/image/${data.id}/`, bodyFormData)
        .then(res=>{
            setLoading(false)
            console.log(res)
            successToast('Successfully uploaded image')
            getImages(data.id)
        }).catch(res=>{
            setLoading(false)
            errorToast('Server Error')
        })

        for (let i in e.target.files){
            if (Number.isInteger(parseInt(i))) {
                URL.revokeObjectURL(e.target.files[i])
            }
        }

    }

    function deleteImage(imageName) {
        setLoading(true)
        console.log(imageName)
        axiosInstance.delete(`${process.env.REACT_APP_API}/operations/delete/image/${data.id}`,  {
            "data": {
                "delete": [imageName]
            }
        }).then(res=>{
            setLoading(false)
            successToast('Successfully delete image')
            getImages(data.id)
        }).catch(res=>{
            setLoading(false)
            errorToast('Server Error')
        })
    }

    return (
        
            <div>
                <div class="row justify-content-center">
                    {loading && (
                        <div class="overlay" style={{backgroundColor:'#FFF', opacity:0.7}}>
                            <i class="fas fa-3x fa-sync-alt"></i>
                        </div>
                    )}
                    {images.length > 0 ? (
                        images.map( (img) => {
                            return (
                                <>
                                <div class="filtr-item col-sm-2">
                                    <span style={{position: 'absolute', backgroundColor: 'white', padding: '5px' }} onClick={()=>{deleteImage(img)}}>x</span>
                                    <a href={img} data-toggle="lightbox">
                                        <img src={img} class="img-fluid mb-2" alt="farmsens-image"/>
                                    </a>
                                </div>
                                </>
                            )
                        })
                    ) : (
                        <div>
                            <p>No Image Uploaded</p>
                        </div>
                    )}

                </div>
                <div className="row">
                    <button className="btn btn-primary">
                        <label htmlFor="img" style={{margin:0}}>
                            Add Image
                            <input
                                id="img"
                                multiple
                                name="img"
                                type="file"
                                accept="image/*;capture=camera"
                                onChange={uploadImage}
                                hidden
                            />
                        </label>
                    </button>
                </div>
                {/* <div className="row">
                    <button className="btn btn-primary">
                        Upload Image
                    </button>
                </div> */}
                <hr/>



                    <form className="form-horizontal">
                        <div className="form-group row">
                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Serial</label>
                            <div className="col-sm-10">
                                <input value={data.serial ||''} disabled type="text" className="form-control"/>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Category</label>
                            <div className="col-sm-10">
                                <input value={data.category ||''} disabled type="text" className="form-control"/>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Datetime</label>
                            <div className="col-sm-10">
                                <input value={formatDateTimeDisplay(data.datetime) ||''} disabled type="text" className="form-control"/>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Submitted By</label>
                            <div className="col-sm-10">
                                <input value={data.username ||''} disabled type="text" className="form-control"/>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Description</label>
                            <div className="col-sm-10">
                                <input value={data.description ||''} disabled type="text" className="form-control"/>
                            </div>
                        </div>
                        
                        <div className="form-group row">
                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Remark</label>
                            <div className="col-sm-10">
                                <input value={data.remark ||''} disabled type="text" className="form-control"/>
                            </div>
                        </div>
                    </form>

            </div>
    )
}

export default NodeViewModal
