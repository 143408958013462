import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import axiosInstance from '../../utils/api';
import { errorToast, successToast } from '../../utils/toast';
import { TextField,Autocomplete } from '@mui/material';

let type = "growth"
const Input = () => {
    const [loading, setLoading] = useState(false)
    let { role } = useParams();
    const [images, setImages] = useState([]) 
    const [treeList, setTreeList] = useState([]) 
    const [serialSelect, setSerialSelect] = useState({
        serial: "",
        breed: "",
        farm: "",
        ref: ""
    })
    const [description, setDescription] = useState("diameter")

    useEffect(() => {
        loadTree()
    }, [])

    function loadTree() {
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/trees`).then(res =>{
            setLoading(false)
            setTreeList(res.data)
        }).catch(err => {
            setLoading(false)
            console.log(err)
        })
    }

    function handleInput(e) {
        setSerialSelect(prev => ({
            ...prev,
            [description] : e.target.value
        }))
    }
    
    function displayImage(e) {
        if (e.target.files.length < 1) {
            return null
        }

        for (let i in e.target.files){
            if (Number.isInteger(parseInt(i))) {
                console.log(e.target.files[i])
                setImages(prev => [...prev, {
                    image_blob: URL.createObjectURL(e.target.files[i]),
                    image_file: e.target.files[i]
                }]);
            }
        }
    }

    function submit() {
        if (serialSelect["serial"] == "") return errorToast("Please select a serial")
        let bodyFormData = new FormData();

        bodyFormData.append('', JSON.stringify(
            {
                "treeid": serialSelect["treeid"],
                "group": "inspection",
                "category": type,  
                "description": description, 
                "remark": serialSelect[description]
            }
        ));

        let counter = 1;
        for (let i in images){
            let file = new File([ images[i].image_file ],  new Date().toISOString() + "_" + counter, {type: "image/jpg", lastModified: Date.now()});
            bodyFormData.append(counter, file);
            counter ++
        }

        setLoading(true)
        axiosInstance.post(`${process.env.REACT_APP_API}/operations/create`, bodyFormData).then(res=>{
            setLoading(false)
            successToast('Success')
            // return window.location.href= `/${role}/operations/${treeFarm}/${id}/activity`
        }).catch(err=>{
            setLoading(false)
            errorToast('Server Error')
            console.log("err", err)
        })
    }

    function handleSerialChange(e,newValue) {
        setSerialSelect(newValue)
    }

    return (
        <div className="content-wrapper">

            <div className="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div className="overlay"><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Input</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href="/">Home</a></li>
                        <li className="breadcrumb-item active">Input</li>
                        </ol>
                    </div>
                    </div>
                </div>


            </div>

            <section className="content">

                <div className="container-fluid">
    
                    <div className="row">
                        <div className="col-12">
                            <div className="text-center p-3">
                                <h4>{capitalize(type)}</h4>
                                <h4>({typeBmTranslate(type)})</h4><br/>
                                {serialSelect.serial != "" && (
                                    <div class="text-center p-3">
                                        <h5>{serialSelect.breed} @ {serialSelect.farm}</h5>
                                        <h5>Serial : {serialSelect.serial} </h5>
                                        <h5>Ref : {serialSelect.ref} </h5>
                                    </div>
                                )}
                                <div className="row my-4 px-4">
                                    <label className="col-12 col-sm-2 col-form-label">Tree Serial</label>
                                    <Autocomplete
                                        className="col-12 col-sm-10"
                                        disableClearable
                                        disablePortal
                                        options={treeList}
                                        getOptionLabel={(option) => option.serial ? `${option.serial} (${option.ref})` : `- (${option.ref})`}
                                        sx={{ width: "100%" }}
                                        value={serialSelect}
                                        onChange={(e, newValue)=>handleSerialChange(e,newValue)}
                                        renderInput={(params) => <TextField {...params} label="Serial" />}
                                    />
                                </div>

                                <div className="row justify-content-center">
                                {images.length > 0 ? (
                                    images.map((image) => (
                                        <div className="col-5 col-md-3 m-2 bg-black" >
                                            <div style={{position:'absolute'}}>
                                                <button onClick={()=>{
                                                    setImages( prev => 
                                                        prev.filter( img => {
                                                            return img.image_blob != image.image_blob
                                                        })
                                                    )
                                                }}>x</button>
                                            </div>

                                            <a href={image.image_blob} data-toggle="lightbox" data-title="Image" data-farm="farm" >
                                                <img src={image.image_blob} className="img-fluid" style={{height:'200px'}} alt="Farmsens-Image"/>
                                            </a>
                                        </div>
                                    ))
                                ) : (
                                    <div>
                                        <p>No Image Uploaded</p>
                                    </div>
                                )}
                                </div>

                                <div className="row justify-content-center">
                                    <button className="btn btn-primary w-100 my-4 px-4 col-12 col-md-3">
                                        <label htmlFor="img" style={{margin:0}}>
                                            Add Image
                                            <input
                                                id="img"
                                                multiple
                                                name="img"
                                                type="file"
                                                accept="image/*;capture=camera"
                                                onChange={displayImage}
                                                hidden
                                            />
                                        </label>
                                    </button>
                                </div>
                                {/* Remark NUMERIC only for category=growth & type=application */}

                                <>
                                <div className="form-group row my-4 px-4">
                                    <label className="col-12 col-sm-2 col-form-label">Description</label>
                                    <select className="col-12 col-sm-10 form-control" onChange={e=>{ setDescription(e.target.value)}} value={description}>
                                        <option value="diameter">Diameter</option>
                                        <option value="height">Height</option>
                                        <option value="ph">pH</option>
                                    </select>
                                </div>
                                
                                <div className="form-group row my-4 px-4">
                                    <label className="col-12 col-sm-2 col-form-label">Remark</label>
                                    <div className="col-12 col-sm-10 px-0">
                                        <input className="form-control" rows="3" type="number" 
                                        placeholder={description=='height'? "m" : (description=='diameter' ? "mm" : "")}
                                        onKeyDown={e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                                        value={serialSelect[description] || ""}
                                        onChange={e=>handleInput(e)} ></input>
                                    </div>
                                </div>
                                </>
                                
                                
                                <div className="row my-5 px-4 justify-content-center">
                                    <button className="btn btn-success w-100 col-12 col-md-3" href="#" onClick={submit}>
                                    Submit
                                    </button>
                                </div>

                            </div>
                        </div>
                    </div>
                
                </div>
            </section>
            
        </div>
    )
}


function capitalize(string) {
    if (string && string.length > 0) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    } 
    return string
}

function typeBmTranslate(s) {
    switch (s) {
        case 'growth':
            return 'Pertumbuhan'
            break
        case 'deficiency':
            return 'Nutirisi'
            break
        case 'pest':
            return 'Perosak'
            break
        case 'disease':
            return 'Penyakit'
            break
        case 'fertilizer':
            return 'Baja'
            break
        case 'pesticide':
            return 'Racun Perosak'
            break
        case 'treatment':
            return 'Rawatan'
            break
        case 'herbicide':
            return 'Racun Rumput'
            break
        case 'fungicide':
            return 'Racun Kulat'
            break
        default:
            return 'undefined'
            break
    }
}

export default Input