import React, {useEffect,useState} from 'react'

import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";


let general_data = [
    {
        category: 'Task 1',
        wet: 3,
        wet_avg: 1,
    },
    {
        category: 'Task 2',
        wet: 3,
        wet_avg: 1,
    },
];
export default function MoistureBarGraph({ title, data_avg, data_current}) {

    const [graphData, setGraphData] = useState([])

    useEffect(() => {
        arrangeData()
    }, [])


    useEffect(() => {
        let root = am5.Root.new("chartdiv-moisture");

        // https://www.amcharts.com/docs/v5/concepts/themes/
        root.setThemes([
            am5themes_Animated.new(root)
        ]);
        
        // Create chart
        // https://www.amcharts.com/docs/v5/charts/xy-chart/
        var chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "panY",
            wheelY: "zoomY"
        }));
        
        chart.get("colors").set("step", 3);
        
        // Add cursor
        // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
        var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
        cursor.lineY.set("visible", false);
        
        
        // Create axes
        // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
        var yRenderer = am5xy.AxisRendererY.new(root, {
            minGridDistance: 30
        });
        
        var yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
            maxDeviation: 0.3,
            categoryField: "category",
            renderer: yRenderer,
            tooltip: am5.Tooltip.new(root, {})
        }));
        
        var xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
            maxDeviation: 0.3,
            renderer: am5xy.AxisRendererX.new(root, {})
        }));
        
        
        // Create series
        // https://www.amcharts.com/docs/v5/charts/xy-chart/series/
        var series = chart.series.push(am5xy.ColumnSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            baseAxis: yAxis,
            valueXField: "close",
            openValueXField: "open",
            categoryYField: "category",
            sequencedInterpolation: true,
            tooltip: am5.Tooltip.new(root, {
                labelText: "[bold]Max[/]: {valueX}\n[bold]Min[/]: {openValueX}"
            })
        }));
        
        series.columns.template.setAll({
            height: 0.5
        });
        
        series.bullets.push(function() {
            return am5.Bullet.new(root, {
                locationX: 0,
                sprite: am5.Circle.new(root, {
                    radius: 5,
                    fill: series.get("fill")
                })
            })
        })
        
        var nextColor = chart.get("colors").getIndex(1);
        
        series.bullets.push(function() {
            return am5.Bullet.new(root, {
                locationX: 1,
                sprite: am5.Circle.new(root, {
                    radius: 5,
                    fill: nextColor
                })
            })
        })

        // Current Series
        var series2 = chart.series.push(am5xy.LineSeries.new(root, {
            name: "Current",
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "current",
            categoryYField: "category",
            sequencedInterpolation: true,
            tooltip: am5.Tooltip.new(root, {
                labelText: "[bold]{name}[/]\n{valueX}"
            })
        }));
        
        series2.strokes.template.setAll({
            visible:false
        });
        
        series2.bullets.push(function() {
            return am5.Bullet.new(root, {
                locationX: 1,
                sprite: am5.Circle.new(root, {
                    radius: 3,
                    stroke: am5.color(0x990000),
                    strokeWidth: 1,
                    fill: am5.color(0x990000)
                })
            })
        })
        
        
        yAxis.data.setAll(graphData);
        series.data.setAll(graphData);
        series2.data.setAll(graphData);
        
        
        // Make stuff animate on load
        // https://www.amcharts.com/docs/v5/concepts/animations/
        series.appear(1000);
        series2.appear(1000);
        chart.appear(1000, 100);


        return () => {
            root.dispose();
        }
    }, [graphData])

    function arrangeData() {
        let arr = []
        for (let i in data_avg) {
            let obj = {}
            for (let x in data_current) {
                if (data_current[x]['farm'] == data_avg[i]['farm']) {
                    obj = {
                        category: data_avg[i]['farm'],
                        avg: parseFloat(data_avg[i]['wet_avg']),
                        current: data_current[x]['wet'],
                        open: parseFloat(data_avg[i]['wet_min']),
                        close: parseFloat(data_avg[i]['wet_max']),
                    }
                    arr.push(obj)
                }
            }
        }
        setGraphData(arr)
    }
    

    return (
        <div className="card">
            <div className="card-header">
                <h4 className="card-title">{title} (%)</h4>
            </div>
            <div className="card-body">
                <div id="chartdiv-moisture" style={{ width: "100%", minHeight: "300px" }}></div>
            </div>
        </div>
    )
}
