import React, {useEffect,useState} from 'react'

import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

let general_data = [
    {
        category: '2020',
        pest: 3,
        disease: 1,
        deficiancy: 1,
    },
    {
        category: '2021',
        pest: 1,
        disease: 2,
        deficiancy: 2,
    },
];
export default function InspectionBarGraph({ title, data}) {

    const [graphData, setGraphData] = useState([])

    useEffect(() => {
        arrangeData()
    }, [])

    useEffect(() => {
        getGraph('chartdiv-inspection', graphData)
    }, [graphData])
    

    function arrangeData() {
        let arr = []
        let obj = {}
        for (let i in data) {
            obj[data[i]['year']] = {
                ...obj[data[i]['year']],
                category: data[i]['year'].toString(),
                [data[i]['category']]: data[i]['count'],
            }
        }

        for (let i in obj) {
            arr.push(obj[i])
        }
        setGraphData(arr)
    }
    
    function  getGraph(element, data) {
        var chart = am4core.create(element, am4charts.XYChart)
        chart.colors.step = 2;
        chart.paddingBottom = 0
        chart.paddingTop = 5
        chart.paddingLeft = 0
        chart.paddingRight = 0
        
        chart.legend = new am4charts.Legend()
        chart.legend.position = 'bottom'
        chart.legend.paddingBottom = 0
        chart.legend.paddingTop = 0
        chart.legend.paddingLeft = 0
        chart.legend.paddingRight = 0
        chart.legend.labels.template.maxWidth = 95
        chart.legend.labels.template.fontSize = 10
        chart.legend.markers.template.width = 10;
        chart.legend.markers.template.height = 10;
        chart.legend.itemContainers.template.paddingTop = 2;
        chart.legend.itemContainers.template.paddingBottom = 2;
        chart.legend.itemContainers.template.paddingRight = 2;
        chart.legend.itemContainers.template.paddingLeft = 2;
        
        var xAxis = chart.xAxes.push(new am4charts.CategoryAxis())
        xAxis.dataFields.category = 'category'
        xAxis.renderer.cellStartLocation = 0
        xAxis.renderer.grid.template.location = 0;
        xAxis.renderer.minGridDistance = 0; 
        xAxis.fontSize = 11
        
        var yAxis = chart.yAxes.push(new am4charts.ValueAxis());
        yAxis.min = 0;
        yAxis.extraMax =  0.7
        yAxis.title.text = "Frequency";
        yAxis.fontSize=10
        yAxis.title.fontSize = 10;
        yAxis.paddingLeft = 0
        yAxis.marginLeft = 0
    
        function createSeries(value, name) {
            var series = chart.series.push(new am4charts.ColumnSeries())
            series.dataFields.valueY = value
            series.dataFields.categoryX = 'category'
            series.name = name
    
            series.events.on("hidden", arrangeColumns);
            series.events.on("shown", arrangeColumns);
    
            var bullet = series.bullets.push(new am4charts.LabelBullet())
            bullet.interactionsEnabled = false
            bullet.dy = 30;
            bullet.label.text = '{valueY}'
            bullet.label.fill = am4core.color('#000')
            bullet.label.truncate = false;
            bullet.label.hideOversized = false;
            bullet.dy = -10;
            bullet.label.fontSize = 10;
            bullet.label.fontWeight = 'bold';
    
            return series;
        }
    
        chart.data = data
    
        createSeries('pest', 'Pest');
        createSeries('deficiency', 'Deficiency');
        createSeries('disease', 'Disease');
        createSeries('growth', 'Growth');

        createSeries('treatment', 'Treatment');
        createSeries('pesticide', 'Pesticide');
        createSeries('herbicide', 'Herbicide');
        createSeries('fungicude', 'Fungicide');
        createSeries('fertilizer', 'Fertilizer');
    
        function arrangeColumns() {
    
            var series = chart.series.getIndex(0);
    
            var w = 1 - xAxis.renderer.cellStartLocation - (1 - xAxis.renderer.cellEndLocation);
            if (series.dataItems.length > 1) {
                var x0 = xAxis.getX(series.dataItems.getIndex(0), "categoryX");
                var x1 = xAxis.getX(series.dataItems.getIndex(1), "categoryX");
                var delta = ((x1 - x0) / chart.series.length) * w;
                if (am4core.isNumber(delta)) {
                    var middle = chart.series.length / 2;
    
                    var newIndex = 0;
                    chart.series.each(function(series) {
                        if (!series.isHidden && !series.isHiding) {
                            series.dummyData = newIndex;
                            newIndex++;
                        }
                        else {
                            series.dummyData = chart.series.indexOf(series);
                        }
                    })
                    var visibleCount = newIndex;
                    var newMiddle = visibleCount / 2;
    
                    chart.series.each(function(series) {
                        var trueIndex = chart.series.indexOf(series);
                        var newIndex = series.dummyData;
    
                        var dx = (newIndex - trueIndex + middle - newMiddle) * delta
    
                        series.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
                        series.bulletsContainer.animate({ property: "dx", to: dx }, series.interpolationDuration, series.interpolationEasing);
                    })
                }
            }
        }
    }


    return (
        <div className="card">
            <div className="card-header">
                <h4 className="card-title">{title}</h4>
            </div>
            <div className="card-body">
                <div id="chartdiv-inspection" style={{ width: "100%", minHeight: "300px" }}></div>
            </div>
        </div>
    )
}
