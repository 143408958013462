import React, {useEffect,useState} from 'react'
import axiosInstance from '../../utils/api'
import { errorToast, successToast } from '../../utils/toast'
import {useParams} from "react-router-dom";

export default function Users() {
    const [loading, setLoading] = useState(false)
    const [users, setUsers] = useState([])
    let { role } = useParams();

    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/users`).then(res=> {
            setLoading(false)
            console.log(res.data)
            setUsers(res.data)
        }).catch(res=>{
            setLoading(false)
            console.log(res)
            errorToast('Server Error')
        })
    }

    const handleDelete = (u) => {
        setLoading(true)
        axiosInstance.delete(`${process.env.REACT_APP_API}/users/delete/${u.username}`).then(res=>{
            setLoading(false)
            successToast('Succeesfully Deleted')
            getData()
        }).catch(res=>{
            setLoading(false)
            errorToast('Server Error')
        })
    }
    return (
        <div className="content-wrapper">

            <div class="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i><div class="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Users</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href={`/${role}`}>Home</a></li>
                        <li className="breadcrumb-item active">Users</li>
                        </ol>
                    </div>
                    </div>
                </div>


            </div>

            <section className="content">

                <div className="container-fluid">



                    <div className="row">
                        <div className="col-12">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title"></h3>

                                    <div class="card-tools">
                                        <button type="button" class="btn btn-block btn-default"  onClick={()=>{ return window.location = "/"+role+"/users/create" }}>Create Users</button>
                                    </div>
                                </div>
                                <div className="card-body table-responsive p-0">
                                    <table className="table table-hover text-nowrap">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {users.length > 0 ? 
                                            users.map( user => (
                                                <tr>
                                                    <td><a href={`/${role}/users/${user.username}/edit`}>{user.username}</a></td>
                                                    <td>
                                                        <a href="javascript:void(0)" class="btn btn-tool" onClick={()=>{
                                                            if (window.confirm('Are you sure you want to delete user?')) {
                                                                handleDelete(user)
                                                            }
                                                        }}>
                                                            <i class="fas fa-trash"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            ))
                                            : (
                                            <tr>
                                                <td>No Data</td>
                                            </tr>
                                            )}
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                
                
                
                </div>
            </section>


            
        </div>
    )
}
