import React, {useEffect,useState} from 'react'
import axios from 'axios'
import { config } from '../../../utils/config';
import { Link, useLocation,useParams } from 'react-router-dom'
import { successToast, errorToast } from '../../../utils/toast';
import  axiosInstance  from '../../../utils/api'

export default function TreesAddEdit() {
    const [loading, setLoading] = useState(false)
    const [type, setType] = useState('add')
    let { treeId, role } = useParams();
    const [inputs, setInputs] = useState({
        breed:'',
        ref:'',
        serial:'',
        year: '',
        height: '',
        latitude: '',
        longitude: '',
    })
    const [images, setImages] = useState([])
    const [nextTree, setNextTree] = useState("")
    const [prevTree, setPrevTree] = useState("")

    useEffect(() => {
        if (treeId) {
            setType('edit')
            getTreeData(treeId)
        } else {
            setType('add')
        }
    }, [treeId])
    
    function getTreeData(t) {
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/trees/show/${t}/`)
        .then(res=>{
            setLoading(false)
            console.log(res.data)
            setInputs(res.data)
            setImages(res.data.image)

            setNextTree(res.data.next_tree)
            setPrevTree(res.data.previous_tree)
        }).catch(res=>{
            setLoading(false)
            errorToast('Server Error')
        })
    }

    function handleInput(e, field) {
        setInputs({
            ...inputs,
            [field]: e.target.value
        })
    }
    function handleSubmit() {
        if (inputs.breed == '' || typeof inputs.breed == 'undefined' ) {
            return errorToast('Please fill in breed')
        }
        var isValid = /^[0-9,.]*$/; // to only accept digits, commas, dot
        if (!isValid.test(inputs.height)) {
            return errorToast('Height cannot contain alphabets')
        } else if (!isValid.test(inputs.diameter)) {
            return errorToast('Diameter cannot contain alphabets')
        }

        if (type == 'edit') {
            setLoading(true)
            axiosInstance.post(`${process.env.REACT_APP_API}/trees/update/${treeId}`, inputs).then(res=>{
                setLoading(false)
                successToast('Succeesfully Updated')
                getTreeData(treeId)
            }).catch(res=>{
                setLoading(false)
                errorToast('Server Error')
                window.location.reload()
            })
        } else if (type=='add') {
            let bodyFormData = new FormData();
            bodyFormData.append('', JSON.stringify(inputs));
            setLoading(true)
            axiosInstance.post(`${process.env.REACT_APP_API}/trees/create`, bodyFormData).then(res=>{
                setLoading(false)
                console.log(res)
                successToast('Succeesfully Created')
                return window.location = '/'+role+'/trees/'
            }).catch(res=>{
                setLoading(false)
                errorToast('Server Error')
                window.location.reload()
            })
        }

    }
    
    function uploadImage(e) {
        if (e.target.files.length < 1) {
            return null
        }
        // console.log(e.target.files)
        let bodyFormData = new FormData();
        let counter = 1;
        for (let i in e.target.files){
            if (Number.isInteger(parseInt(i))) {
                let file = new File([e.target.files[i]], new Date().toISOString() + "_" + counter, {type: "image/jpg", lastModified: Date.now()});
                bodyFormData.append(counter, file);
            }
            counter ++
        }

        setLoading(true)
        axiosInstance.post(`${process.env.REACT_APP_API}/trees/update/image/${treeId}`, bodyFormData)
        .then(res=>{
            setLoading(false)
            console.log(res)
            successToast('Successfully uploaded image')
            getTreeData(treeId)
        }).catch(res=>{
            setLoading(false)
            // console.log(res)
            errorToast('Server Error')
            window.location.reload()
        })

        for (let i in e.target.files){
            if (Number.isInteger(parseInt(i))) {
                URL.revokeObjectURL(e.target.files[i])
            }
        }


    }

    function deleteImage(imageName) {
        setLoading(true)
        axiosInstance.delete(`${process.env.REACT_APP_API}/trees/delete/image/${treeId}`,  {
            "data": {
                "delete": [imageName]
            }
        }).then(res=>{
            setLoading(false)
            successToast('Successfully delete image')
            getTreeData(treeId)
        }).catch(res=>{
            setLoading(false)
            errorToast('Server Error')
            window.location.reload()
        })
    }


    return (
        <div className="content-wrapper">

            <div class="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i><div class="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Tree Detail</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                            <li className="breadcrumb-item"><a href={`/${role}`}>Home</a></li>
                            <li className="breadcrumb-item"><a href={`/${role}/trees`}>Trees</a></li>
                            <li className="breadcrumb-item active">Tree Detail</li>
                        </ol>
                    </div>
                    </div>
                </div>
            </div>
            
            {type=='edit' && (
                <div className="row px-3 py-1" style={{width:'100%', margin:'auto', justifyContent:'space-between'}}>
                    <Link to={`/admin/trees/${prevTree}/edit`}>
                        <button className='btn btn-sm btn-primary'>
                        PREVIOUS
                        </button>
                    </Link>
                    <Link to={`/admin/trees/${inputs.treeid}/view`}>
                        <button className='btn btn-sm btn-secondary'>
                        VIEW
                        </button>
                    </Link>
                    <Link to={`/admin/trees/${nextTree}/edit`}>
                        <button className='btn btn-sm btn-primary'>
                            NEXT
                        </button>
                    </Link>
                </div>
            )}

            <section className="content">
                <div className="container-fluid">

                    {type=='edit' && (
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card card-primary card-outline">
                                    <div className="card-header">
                                        <h3 className="card-title">Tree Image</h3>
                                    </div>

                                    <div className="card-body"> 
                                        <div class="filter-container p-0 row">
                                            {images.length>0 && images.map(img=>{
                                                return (
                                                    <>
                                                    <div class="filtr-item col-sm-2">
                                                        <span style={{position: 'absolute', backgroundColor: 'white', padding: '5px' }} onClick={()=>{deleteImage(img)}}>x</span>
                                                        <a href={img} data-toggle="lightbox">
                                                            <img src={img} class="img-fluid mb-2" alt="farmsens-image"/>
                                                        </a>
                                                    </div>
                                                    </>
                                                )
                                            })}
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <button className="btn btn-primary mx-2">
                                            <label htmlFor="img" style={{margin:0}}>
                                                Add Image
                                                <input
                                                    id="img"
                                                    multiple
                                                    name="img"
                                                    type="file"
                                                    accept="image/*;capture=camera"
                                                    onChange={uploadImage}
                                                    hidden
                                                />
                                            </label>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="row">
                        
                        <div className="col-md-12">
                            <div className="card card-primary card-outline">
                                <div className="card-header">
                                    <h3 className="card-title">Tree Info</h3>
                                </div>

                                <form className="form-horizontal">
                                    <div className="card-body">
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Serial</label>
                                            <div className="col-sm-10">
                                                <input value={inputs.serial ||''} onChange={(e)=>handleInput(e,'serial')} type="text" className="form-control" id="library-name" placeholder="Required"/>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Breed</label>
                                            <div className="col-sm-10">
                                                <input value={inputs.breed ||''} onChange={(e)=>handleInput(e,'breed')} type="text" className="form-control" id="library-name" placeholder="Required"/>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Ref</label>
                                            <div className="col-sm-10">
                                                <input value={inputs.ref ||''} onChange={(e)=>handleInput(e,'ref')} type="text" className="form-control" id="library-name" placeholder="Required"/>
                                            </div>
                                        </div>
                                        
                                    {type=='edit' && (
                                        <>
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Year</label>
                                            <div className="col-sm-10">
                                                <input value={inputs.year ||''} onChange={(e)=>handleInput(e,'year')} type="text" className="form-control" id="library-name"/>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Height (m)</label>
                                            <div className="col-sm-10">
                                                <input disabled value={inputs.height ||''} onChange={(e)=>handleInput(e,'height')} className="form-control" id="library-name"/>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Diameter (mm)</label>
                                            <div className="col-sm-10">
                                                <input disabled value={inputs.diameter ||''} onChange={(e)=>handleInput(e,'diameter')} className="form-control" id="library-name"/>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Latitude</label>
                                            <div className="col-sm-10">
                                                <input value={inputs.latitude ||''} onChange={(e)=>handleInput(e,'latitude')} type="text" className="form-control" id="library-name"/>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Longitude</label>
                                            <div className="col-sm-10">
                                                <input value={inputs.longitude ||''} onChange={(e)=>handleInput(e,'longitude')} type="text" className="form-control" id="library-name"/>
                                            </div>
                                        </div>
                                        <div className="form-group row">
                                            <label htmlFor="inputEmail3" className="col-sm-2 col-form-label">Remark</label>
                                            <div className="col-sm-10">
                                                <input value={inputs.remark ||''} onChange={(e)=>handleInput(e,'remark')} type="text" className="form-control" id="library-name"/>
                                            </div>
                                        </div>
                                        </>
                                    )}
                                    </div>
                                </form>
                            </div>
                        </div>

                        
                        

                    </div>

                    <div className="row p-3">
                        <button type="submit" className="btn btn-primary btn-md" onClick={handleSubmit}>{type=='add'?'Create':'Update'}</button>
                    </div>
                </div>
            </section>

            
        </div>
    )
}
