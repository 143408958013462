import React, {useEffect,useState} from 'react'
import {useParams} from "react-router-dom";
import axiosInstance from '../../../utils/api';
import ApplicationBarGraph from '../../dashboard/components/ApplicationBarGraph';
import InspectionBarGraph from '../../dashboard/components/InspectionBarGraph';

export default function FarmSummary() {
    const [loading, setLoading] = useState(false)
    let { role,farmId } = useParams();
    const [dashboardData, setDashboardData] = useState({
        application: [],
        breed: [],
        current_soil: [],
        current_weather: {},
        event: {},
        year_soil: {},
        year_weather: {},
    })


    useEffect(() => {
        RenderInspectionGraph()
        RenderApplicationGraph()
    }, [dashboardData])

    useEffect(() => {
        getData()
    }, [])
    
    function getData() {
        setLoading(true)
        axiosInstance.get(`${process.env.REACT_APP_API}/summary/farm/${farmId}`).then(res=>{
            setLoading(false)
            setDashboardData(res.data)
        }).catch(res=> {
            setLoading(false)
            console.log(res)
        })
    }

    function RenderInspectionGraph() {
        return (<InspectionBarGraph title="Inspection" data={dashboardData.event}/>)
    }
    function RenderApplicationGraph() {
        return(<ApplicationBarGraph title="Application"  data={dashboardData.application}/>)
    }
    return (
        <div className="content-wrapper">

            <div class="overlay-wrapper" style={{ display: (loading?'':'none') }}>
                <div class="overlay"><i class="fas fa-3x fa-sync-alt fa-spin"></i><div class="text-bold pt-2"></div></div>
            </div>
            
            <div className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                    <div className="col-sm-6">
                        <h1 className="m-0">Farm Summary</h1>
                    </div>
                    <div className="col-sm-6">
                        <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><a href={`/${role}`}>Home</a></li>
                        <li className="breadcrumb-item"><a href={`/${role}/farm`}>Farm</a></li>
                        <li className="breadcrumb-item active">Farm Summary</li>
                        </ol>
                    </div>
                    </div>
                </div>


            </div>

            <section className="content">

                <div className="container-fluid">


                    <div className="row">
                        <div class="col-12 col-md-7">
                            <div className="row">
                                <div className="col-12 col-md-6 row my-2">
                                    <div className="col-4 text-center">
                                        <i class="fas fa-temperature-high mb-3" style={{fontSize: '50px'}}></i><br/>
                                        <span>{dashboardData.current_weather.t}</span><br/>
                                        <span>Temp (C)</span>
                                    </div>
                                    <div className="col-4 text-center">
                                        <i class="fas fa-tint mb-3" style={{fontSize: '50px'}}></i><br/>
                                        <span>{dashboardData.current_weather.h}</span><br/>
                                        <span>Humidity (%)</span>
                                    </div>
                                    <div className="col-4 text-center">
                                        <i class="fas fa-cloud-rain mb-3" style={{fontSize: '50px'}}></i><br/>
                                        <span>{dashboardData.current_weather.rainfall}</span><br/>
                                        <span>Rainfall (mm)</span>
                                    </div>
                                </div>
                                <div className="col-12 col-md-6 row my-2">
                                    <div className="col-3 text-center">
                                        <i class="fas fa-compass mb-3" style={{fontSize: '50px'}}></i><br/>
                                        <span>{dashboardData.current_weather.wd}</span><br/>
                                        <span>Wind Direction (deg)</span>
                                    </div>
                                    <div className="col-3 text-center">
                                        <i class="fas fa-wind mb-3" style={{fontSize: '50px'}}></i><br/>
                                        <span>{dashboardData.current_weather.ws}</span><br/>
                                        <span>Wind Speed (m/s)</span>
                                    </div>
                                    <div className="col-3 text-center">
                                        <i class="fas fa-sun mb-3" style={{fontSize: '50px'}}></i><br/>
                                        <span>{dashboardData.current_weather.par}</span><br/>
                                        <span>PAR (μmol/㎡·s)</span>
                                    </div>
                                    <div className="col-3 text-center">
                                        <img src="/img/icons/gauge.png" style={{width: '70px'}}/><br/>
                                        <span>{dashboardData.current_weather.bp}</span><br/>
                                        <span>BarP (kPa)</span>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col-12 col-md-5">
                            <div className="row">
                                <div className="col-3 text-center">
                                    <img src="/img/icons/soiltemp.png" alt="temp" style={{width: 80, height:'auto'}}/><br/>
                                    <span>{dashboardData.current_soil.soilph}</span><br/>
                                    <span>pH</span>
                                </div>
                                <div className="col-3 text-center">
                                    <img src="/img/icons/soilec.png" alt="humidity" style={{width: 80, height:'auto'}}/><br/>
                                    <span>{dashboardData.current_soil.soilec}</span><br/>
                                    <span>EC</span><br/><span>(mS/cm)</span>
                                </div>
                                <div className="col-3 text-center">
                                    <img src="/img/icons/soilwet.png" alt="temp" style={{width: 80, height:'auto'}}/><br/>
                                    <span>{dashboardData.current_soil.soilt}</span><br/>
                                    <span>Temp</span><br/><span>(C)</span>
                                </div>
                                <div className="col-3 text-center">
                                    <img src="/img/icons/soilwet.png" alt="temp" style={{width: 80, height:'auto'}}/><br/>
                                    <span>{dashboardData.current_soil.wet}</span><br/>
                                    <span>Moisture</span><br/><span>(%)</span>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <div className="card w-100">
                                <div className="card-header">
                                    <h3 className="card-title">Historical Min Max</h3>
                                </div>
                                <div className="card-body table-responsive p-0">
                                    <table className="table table-hover text-nowrap">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Min</th>
                                                <th>Max</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>Temperature(C)</td>
                                                <td>{dashboardData.year_weather.t_min}</td>
                                                <td>{dashboardData.year_weather.t_max}</td>
                                            </tr>
                                            <tr>
                                                <td>Humidity (%)</td>
                                                <td>{dashboardData.year_weather.h_min}</td>
                                                <td>{dashboardData.year_weather.h_max}</td>
                                            </tr>
                                            <tr>
                                                <td>Barometric Pressure (kPa)</td>
                                                <td>{dashboardData.year_weather.bp_min}</td>
                                                <td>{dashboardData.year_weather.bp_max}</td>
                                            </tr>
                                            <tr>
                                                <td>Wind Speed(m/s)</td>
                                                <td>{dashboardData.year_weather.ws_min}</td>
                                                <td>{dashboardData.year_weather.ws_max}</td>
                                            </tr>
                                            <tr>
                                                <td>PAR(μmol/㎡·s)</td>
                                                <td>{dashboardData.year_weather.par_min}</td>
                                                <td>{dashboardData.year_weather.par_max}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="card w-100">
                                <div className="card-header">
                                    <h3 className="card-title">Historical Min Max</h3>
                                </div>
                                <div className="card-body table-responsive p-0">
                                    <table className="table table-hover text-nowrap">
                                        <thead>
                                            <tr>
                                                <th>Parameter</th>
                                                <th>Min</th>
                                                <th>Max</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>EC(mS/cm)</td>
                                                <td>{dashboardData.year_soil.soilec_min}</td>
                                                <td>{dashboardData.year_soil.soilec_max}</td>
                                            </tr>
                                            <tr>
                                                <td>pH</td>
                                                <td>{dashboardData.year_soil.soilph_min}</td>
                                                <td>{dashboardData.year_soil.soilph_max}</td>
                                            </tr>
                                            <tr>
                                                <td>Moisture(%)</td>
                                                <td>{dashboardData.year_soil.wet_min}</td>
                                                <td>{dashboardData.year_soil.wet_max}</td>
                                            </tr>
                                            <tr>
                                                <td>Temp(C)</td>
                                                <td>{dashboardData.year_soil.soilt_min}</td>
                                                <td>{dashboardData.year_soil.soilt_max}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12 col-md-6">
                            <RenderInspectionGraph />
                        </div>
                        <div className="col-12 col-md-6">
                            <RenderApplicationGraph />
                        </div>
                    </div>
                </div>
            </section>


            
        </div>
    )
}