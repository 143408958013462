import React, {useEffect, useState} from 'react'

import TopNav from '../components/TopNav';
import SideNav from '../components/SideNav';
import Footer from '../components/Footer';
import { WorkerRoute } from '../routes/WorkerRoute';

import Operations from '../pages/operations/Operations';
import Error404 from '../pages/errors/Error404';
import { Route, Switch } from 'react-router-dom';
import ScanQr from '../pages/operations/ScanQr';
import Activity from '../pages/operations/Activity';
import Inspection from '../pages/operations/Inspection';
import Application from '../pages/operations/Application';
import Form from '../pages/operations/Form';
import SelectTrees from '../pages/operations/SelectTrees';
import SelectFarms from '../pages/operations/SelectFarms';
import TreesView from '../pages/trees/pages/TreesView';
import Input from '../pages/input/Input';

const Worker = (props) => {
	const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('token:access'))
	const [role, setRole] = useState('worker')
    useEffect(() => {
        if (localStorage.getItem('token:access')) {
            setIsAuthenticated(true)
            if (localStorage.getItem('user:data') && JSON.parse(localStorage.getItem('user:data'))) {
                let user_role = JSON.parse(localStorage.getItem('user:data')).role
                if (user_role == 'admin') {
                    setRole('admin')
                } else {
                    setRole('worker')
                }
            }
        } else {
            setIsAuthenticated(false)
        }
        console.log('Worker.js')
	}, [])

    if (role !='worker' || !isAuthenticated) {
        return <Error404 />
    }

    return (
        <div className="App" className="hold-transition sidebar-mini layout-fixed">
            <div className="wrapper">
                <TopNav/>
                <SideNav role={role}/>

                <Switch>

                    <WorkerRoute {...props}  path={`/:role/trees/:treeId/view`} component={TreesView} />

                    {/* OPERATIONS  */}
                    <WorkerRoute {...props} exact path={`/:role/operations`} component={Operations} />
                    <WorkerRoute {...props} exact path={`/:role/operations/scan-qr/`} component={ScanQr} />

                    
                    <WorkerRoute {...props} exact path={`/:role/operations/:treeFarm(tree|farm)/:treeId/activity/`} component={Activity} />
                    <WorkerRoute {...props} exact path={`/:role/operations/:treeFarm(tree|farm)/:treeId/inspection/`} component={Inspection} />
                    <WorkerRoute {...props} exact path={`/:role/operations/:treeFarm(tree|farm)/:treeId/application/`} component={Application} />
                    <WorkerRoute {...props} exact path={`/:role/operations/:treeFarm(tree|farm)/:id/:inspectionApplication(inspection|application)/:type`} component={Form} />

                    
                    <WorkerRoute {...props} exact path={`/:role/operations/select-trees/`} component={SelectTrees} />
                    <WorkerRoute {...props} exact path={`/:role/operations/select-farms/`} component={SelectFarms} />
                    
                    {/* INPUT  */}
                    <WorkerRoute {...props} exact path={`/:role/input`} component={Input} />

                    <Route path="*" >
                        <Error404 />
                    </Route>

                </Switch>
                <Footer/>
            </div>
        </div>
    )
}

export default Worker